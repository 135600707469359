import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';
// import { Layout, Menu, Icon } from 'antd';

// this contains icons
// import 'antd/lib/style/index.css';

// global styles are still included with components
import { Layout, Icon, message, Row, Col, Card, notification, Button } from 'antd';
// import 'antd/dist/antd.less';

import GlobalHeader from '../GlobalHeader';
import GlobalFooter from '../GlobalFooter';

import styles from './style.less';

import logo from '../../assets/witch_logo.svg';
import logoSmall from '../../assets/witch_logo_small.svg';


// NOTE not 100% sure if this is a proper way of injecting config.versionInfo here;
//		it's a property of the build/global constant on the other hand
import config from '../../utils/config';


const { Content } = Layout;

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
};

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile,
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.onNoticeClose = this.onNoticeClose.bind(this);
  }

  componentDidMount() {
    enquireScreen((mobile) => {
      this.setState({
        isMobile: mobile,
      });
    });
    if (!getCookie('grammar-error-notice-cookie'))
      notification.open({
        key: 'grammar-error-notice-cookie',
        btn: (<Button type="primary" onClick={() => this.onNoticeClose('grammar-error-notice-cookie')}>OK</Button>),
        duration: 0,
        description: (
          <div>
            <p>
              We use cookies for a number of reasons such as keeping our Site reliable and secure, personalising content and ads, and analysing how the Site is used. By accessing the Site, you agree to use of cookies on this device in accordance with our <a target='_blank' href='/legal/privacypolicy-en'>Privacy Policy</a>.
            </p>
          </div>
        ),
        placement: 'bottomLeft',
        onClose: this.onNoticeClose
      });
    // this.props.dispatch({
    //   // TODO fetch user here
    //   type: 'user/fetchCurrent',
    // });
  }

  // eslint-disable-next-line
  getPageTitle() {
    const title = 'English Challenges';
    return title;
  }

  onNoticeClose = (key) => {
    //Expiry dates PREVENT it from being deleted when the browser is closed
    var expiration_date = new Date();
    expiration_date.setFullYear(expiration_date.getFullYear() + 1);
    document.cookie = "grammar-error-notice-cookie=true; path=/; expires=" + expiration_date.toUTCString();
    notification.close(key);
  };

  // FIXME > change this.props.push  to this.props.dispatch(<action>) later
  handleMenuClick = ({ key }) => {
    // FIXME = use our actions
    switch (key) {
      case 'triggerError':
        // this.props.dispatch(push('/exception/trigger'));
        break;
      case 'login':
        // this.props.userLogin();
        break;
      case 'logout':
        // this.props.dispatch({
        //   type: 'login/logout',
        // });
        break;
      case 'dashboard':
        // this.props.push('/dashboard');
        break;
      case 'home':
        // this.props.push('/');
        break;
      default:
        break;
    }
  }

  handleNoticeClear = (type) => {
    // message.success(`清空了${type}`);
    // // FIXME >> use our action
    // this.props.dispatch({
    //   type: 'global/clearNotices',
    //   payload: type,
    // });
  }

  handleNoticeVisibleChange = (visible) => {
    // FIXME >> use our action
    // if (visible) {
    //   this.props.dispatch({
    //     type: 'global/fetchNotices',
    //   });
    // }
  }

  /* FIXME move styles to .css or .less */
  render() {
    const banner1 = `
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7753869357226646" crossorigin="anonymous"></script>
      <!-- 2right_300x250_1 -->
      <ins class="adsbygoogle"
          style="display:inline-block;width:300px;height:250px"
          data-ad-client="ca-pub-7753869357226646"
          data-ad-slot="7635855046"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>`;
    const banner2 = `
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7753869357226646" crossorigin="anonymous"></script>
      <!-- 2right_300x250_2 -->
      <ins class="adsbygoogle"
          style="display:inline-block;width:300px;height:250px"
          data-ad-client="ca-pub-7753869357226646"
          data-ad-slot="2435499255"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>`;

    const layout = (
      <Row>
        <Col xs={24} sm={24} md={14} lg={14} xl={15} xxl={17}>
          <Content className={styles.content}>
            <div>
              { this.props.children }
            </div>
          </Content>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={9} xxl={7}>
          <Card bordered={false} className={styles.bannerCard} bodyStyle={{padding:'0'}}>
          { config.showAds ? (
            <React.Fragment>
							<div 
								style={{ width: 300, height: 250, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, border: '1px solid black' }}
								dangerouslySetInnerHTML={{__html: banner1}}
							>
              </div>
              <div style={{ width: 300, height: 250, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, border: '1px solid black' }}
								dangerouslySetInnerHTML={{__html: banner2}}
							>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ width: 300, height: 250, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, border: '1px solid black' }}>
              JUST BANNER PLACEHOLDERS 300x250
              </div>
              <div style={{ width: 300, height: 250, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, border: '1px solid black' }}>
              JUST BANNER PLACEHOLDERS 300x250
              </div>
            </React.Fragment>
          )}
          </Card>
        </Col>
      </Row>
    );

    return (
      <Layout className="layout">
        <GlobalHeader
          logo={`/${logo}`}
          logoSmall={`/${logoSmall}`}
          isMobile={this.state.isMobile}
          onNoticeClear={this.handleNoticeClear}
          onCollapse={this.handleMenuCollapse}
          onMenuClick={this.handleMenuClick}
					onNoticeVisibleChange={this.handleNoticeVisibleChange}
					versionInfo={ config.versionInHeader ? config.versionInfo : null }
          forumUrl={ config.featureFlags && (config.featureFlags.forum ? config.forumUrl : null) }
					history={this.props.history}
        />

        <Row type="flex" justify="center">
          <Col span={24} lg={22} xl={20} xxl={18}>{layout}</Col>
        </Row>

        <GlobalFooter
          links={[
            { title: 'Terms of Service', href: `/legal/termsofuse`, key: `/legal/termsofuse`},
            { title: 'Privacy Policy', href: `/legal/privacypolicy`, key: `/legal/privacypolicy`}
          ]}
          copyright={
            <div>
              Copyright <Icon type="copyright" /> 2018 Kind Robots Ltd.
            </div>
					}
					versionInfo={config.versionInfo}
        />

      </Layout>
    );
  }
}

Root.defaultProps = {
  user: null,
};

Root.propTypes = {
  children: PropTypes.node.isRequired,
//  userLogin: PropTypes.func.isRequired,
//  push: PropTypes.func.isRequired,
  user: PropTypes.shape({
    expired: PropTypes.bool.isRequired,
  }),
};




export default withRouter(Root);
