import React from 'react';
import { Card, Breadcrumb, Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import TagMarkdown from '../TagMarkdown';

import LicenseDisplay from '../../components/LicenseDisplay';

import styles from './style.less';

const TagDetailsComponent = ({
  data,
  taxonomy
}) => {
  const path = taxonomy.getBreadcrumb(data);
  const pathItems = path.map(item => {
    return (
      <Breadcrumb.Item key={item.key}>
        { item.key != data.slug ? (
          <Link to={`/tags/${item.key}`}>
            {item.title}
          </Link>
          ) : (
          item.title
          )
        }
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [(
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>
  )].concat(pathItems);

  let seealso = taxonomy.getChildren(data);
  if (!seealso.length)
    seealso = taxonomy.getSiblings(data);

	seealso && seealso.sort((a,b) => ((a.title > b.title) - (a.title < b.title)));

  return (
    <Card className={styles.card} title={
      <Breadcrumb>
        {breadcrumbItems}
      </Breadcrumb>
    }>
      {/* <h1>{data.title}</h1> */}
			<TagMarkdown
				className={`forumContent ${styles.tagDescription}`}
				source={data.description}
			/>
      {data.license && data.descriptionUrls ? (
        <LicenseDisplay license={data.license} source="wikipedia" descriptionUrls={data.descriptionUrls} />
      ) : ''}
      { seealso.length ? (<h1>See also</h1>) : ('') }
      { seealso.map(item => {
        return (
          <p key={item.key}>
            <Link to={`/tags/${item.key}`}>
              {item.title}
            </Link>
          </p>
        );
      })
      }
      <p>
        <Link to={`/challenges/${data.slug}`} rel="nofollow">
          <Button size='large' type="primary">
            Show challenges for the tag <Icon type="caret-right" />
          </Button>
        </Link>
      </p>
    </Card>
  );
};

export default TagDetailsComponent;
