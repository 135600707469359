import React from 'react';
import { Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import styles from './style.less';

const TaxonomyTagsComponent = ({
  tags,
  taxonomy
}) => {
  return (
    <div className={styles.taxonomyTags} >
      {tags ? taxonomy.difficultyCefrThenRest(tags).map((object, i) => {
        return (
				<Tooltip key={object.uuid} title="Click to read more...">
					<Link to={`/tags/${object.slug}`} target="_blank">
						<Tag className={styles.taxonomyTag} color={taxonomy.findColor(object.slug)}>{object.title}</Tag>
					</Link>
				</Tooltip>
        );
      }) : ''}
    </div>
  );
};

export default TaxonomyTagsComponent;
