/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { Query } from 'react-apollo';

import PageTitle from '../components/PageTitle';
import LegalPageComponent from '../components/LegalPage';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { GET_LEGAL_PAGE } from '../constants/queries';

class LegalPage extends React.Component {
  render() {
    const { staticContext, history } = this.props;
    const { params } = this.props.match;

    return (
      <Query
        query={GET_LEGAL_PAGE}
        variables={{
          documentSlug: params.documentSlug
        }}
      >
        {({ loading, error, data, client }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error)
            return (<Exception desc={error.message} staticContext={staticContext} />);
          if (null == data.legalDocumentBySlug)
            return (<Exception type={404} staticContext={staticContext} />);

          const legalData = data.legalDocumentBySlug;

          if (staticContext && legalData.title) staticContext.pageTitle = legalData.title;

          return (<>
            <LegalPageComponent
              history={history}
							legalData={legalData}
							ru={legalData.slug && legalData.slug.endsWith("-ru")}
            />
            <PageTitle pageTitle={legalData.title} />
          </>);
        }}
      </Query>
    );
  }
}

export default LegalPage;
