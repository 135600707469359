/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { DragDropContextProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import { Question } from '@kindrobots/web.fe.ui';
import Exception from '../components/Exception';
import LoadingIndicator from '../components/LoadingIndicator';
import QuestionComponent from '../components/Question';

import { ensureUser } from './EnsureUser';
import config from '../utils/config';
import { addTestLocalAnswer, resetTestLocalAnswers, getTestLocalAnswers } from '../utils/localState';
import { selectByTags } from '../utils/cefrTestUtils';
import { getLoginManager } from '../utils/loginManager';
import * as TestType from '../constants/testType';
import { TEST_CHALLENGE_SLUG } from '../constants/testConstants';
import { GET_ALL_QUESTIONS, GET_PROFILE } from '../constants/queries';

const nQuestionsPerSection = 10;

const SUBMIT_REPORT = gql`mutation ($report: TestReportInput!) {
  submitTestReport(challengeSlug: "test-your-english-level", report: $report) {
    uuid
    challengeUuid
    userId
    json
    createdAt   
  }
}`;

const GET_CHALLENGE_DATA = gql`query ($challengeSlug: String!) {
  challengeBySlug(slug: $challengeSlug) {
    uuid
    title
  }
}`;

const generateQuestions = (questions_data) => {
  const allQuestions = questions_data.questionsWithAuth.filter(q => !q.nonTest);
  const a1a2 = selectByTags(allQuestions, ['cefr-a1', 'cefr-a2'], [], nQuestionsPerSection);
  const b1b2 = selectByTags(allQuestions, ['cefr-b1', 'cefr-b2'], [], nQuestionsPerSection);
  const c1c2 = selectByTags(allQuestions, ['cefr-c1', 'cefr-c2'], [], nQuestionsPerSection);
  return [...a1a2, ...b1b2, ...c1c2];
};

export const toTestAnswer = (answer) => {
  const aq = answer.question;
  return ({
    question: {
      uuid: aq.uuid,
      slug: aq.slug,
      type: aq.type,
      text: aq.text,
      author: aq.author,
      createdAt: aq.createdAt,
      createdBy: aq.createdBy,
      modifiedAt: aq.modifiedAt,
      task: aq.task,
      comment: aq.comment,
      hrefs: aq.hrefs,
      requiredAnswers: aq.requiredAnswers,
      tags: aq.tags.map(t => ({ uuid: t.uuid, slug: t.slug, title: t.title }))
    },
    answerElements: answer.answerElements,
  });
};

const TestQuestionContainer = (props) => {
  const { params } = props.match;

  const toHome = () => {
    props.history.push('/');
  };

  const submitReportCallback = (
    cache, { data: { submitTestReport } }
  ) => {
    resetTestLocalAnswers();
    // TODO return suggestion to register pop-up
    // if (this.loginProposalRef.current && this.loginProposalRef.current.isVisible()) {
    //   this.setState({
    //     loginProposalLandingURL: `/report/${submitChallengeAnswers.sessionUuid}/${challengeSlug}`
    //   });
    // } else {
    //   this.props.history.push(`${this.props.embedded ? '/embed' : ''}/report/${submitChallengeAnswers.sessionUuid}/${challengeSlug}`);
    // }
    props.history.push(`/test-your-english-language-cefr-level/report/${submitTestReport.uuid}`);
  };

  const questionsRef = useRef(null);
  const [questionIndex, setQuestionIndex] = useState(0);

  return (
    <DragDropContextProvider backend={MultiBackend(HTML5toTouch)}>
      <Query query={GET_ALL_QUESTIONS}>
        {({ loading: questions_loading, data: questions_data, error: questions_error }) => (
          <Query query={GET_CHALLENGE_DATA} variables={{ challengeSlug: TEST_CHALLENGE_SLUG }}>
            {({ loading: challenge_loading, data: challenge_data, error: challenge_error }) => (
              <Mutation
                mutation={SUBMIT_REPORT} /* NOTE ??? Do we need it? key={id} */
                update={submitReportCallback}
                refetchQueries={!questions_data.nextQuestionSlug && !getLoginManager().isUserAnonymous(props.user)
                  ? [{
                    query: GET_PROFILE
                  }]
                  : []
            }
                onError={error => console.log(JSON.stringify(error))}
              >
                {(triggerSubmitAnswerMutation, { data: submit_answers_data, loading: submit_answers_loading, error: submit_answers_error }) => {
                  if (questions_loading || challenge_loading || submit_answers_loading) {
                    return (<LoadingIndicator />);
                  }

                  if (questions_error || challenge_error || submit_answers_error) {
                    return <Exception type={500} />;
                  }

                  if (!questionsRef.current) {
                    questionsRef.current = generateQuestions(questions_data);
                  }
                  const questions = questionsRef.current;
                  const submitAnswer = (submitMutationCallback, answer) => {
                    addTestLocalAnswer(toTestAnswer(answer));
                    const last = (questionIndex === questions.length - 1);

                    if (last) {
                      submitMutationCallback({
                        variables: {
                          challengeSlug: TEST_CHALLENGE_SLUG,
                          report: {
                            json: JSON.stringify(getTestLocalAnswers())
                          }
                        }
                      });
                    } else {
                      setQuestionIndex(questionIndex + 1);
                    }
                  };

                  // show question
                  const questionData = new Question({
                    ...questions[questionIndex],
                    challengeSlug: TEST_CHALLENGE_SLUG,
                    challengeTitle: challenge_data.challengeBySlug.title,
                    nextQuestionSlug: questionIndex < questions.length - 1 ? questions[questionIndex + 1].slug : null,
                    questionsTotalNumber: questions.length,
                    currentQuestionNumber: questionIndex + 1,
                  });

                  return (
                    <QuestionComponent
                      mutating={false}
                      question={questionData}
                      taxonomy={props.taxonomy}
                      key={questionData.slug}
                      submitAnswer={answer => submitAnswer(triggerSubmitAnswerMutation, answer)}
                      onExit={toHome}
                      path={location.pathname}
                      site={config.webUrl}
                      testType={TestType.CEFR_LEVEL_TEST}
                    />
                  );
                }
          }
              </Mutation>
            )
      }
          </Query>
        )}
      </Query>
    </DragDropContextProvider>
  );
};

export default ensureUser(TestQuestionContainer);
