// This is client-only component

import React from 'react';
import { Dropdown, Menu, Icon, Avatar } from 'antd';
import { window } from '../../utils/ssrUtils';
import { Nav, buildReturnPath } from '../Nav';
import { withLoginProvider } from '../../containers/LoginProvider';
import { getLoginManager } from '../../utils/loginManager';
import { clientOnly } from '../../containers/ClientOnly';

import styles from './style.less';


const LoginInfo = ({user, isLoadingUser, userError, history}) => {
	const menu = <Menu theme='dark' className={styles.loggedInMenu}>
		<Menu.Item key="profile"><Nav.ProfileLink returnPath={buildReturnPath(window)} ><Icon type="user" /> Profile</Nav.ProfileLink></Menu.Item>
		<Menu.Item key="settings"><Nav.SettingsLink returnPath={buildReturnPath(window)} ><Icon type="setting" /> Settings</Nav.SettingsLink></Menu.Item>
		<Menu.Divider/>
		<Menu.Item key="logout"><Nav.LogoutLink returnPath={buildReturnPath(window)}><Icon type="logout" /> Logout</Nav.LogoutLink></Menu.Item>
	</Menu>;

	return user && !getLoginManager().isUserAnonymous(user)
		? <Dropdown overlay={menu}>
				<a className="ant-dropdown-link"><Avatar icon="user" size="small" />&nbsp;&nbsp;{user.profile && user.profile.name} <Icon type="caret-down" /> </a>
			</Dropdown>
		: <>
				<Nav.LoginLink className={styles.login} returnPath={buildReturnPath(window)}>Log in</Nav.LoginLink>
				<Nav.RegisterButton className={styles.register} returnPath={buildReturnPath(window)}>Sign up</Nav.RegisterButton>
			</> ;
};

export default clientOnly(withLoginProvider(LoginInfo));


