import { isServer } from './ssrUtils';

let config = {};
if (process.env.REACT_APP_CONFIG) {
	config = JSON.parse(process.env.REACT_APP_CONFIG);
	config.versionInfo = config.versionInfo || {
		version: { major: "X", minor: "Y", patch: "Z" },
		git: { hash: "" },
		date: ""
	};
}
config.showAds = (process.env.DEPLOY_ENV == "Production");
config.versionInHeader = (process.env.DEPLOY_ENV != "Production");

//server-side override
if (isServer()) {
	const DeployEnv = process.env.DEPLOY_ENV || 'Development';
	const path = require('path');
	const fs = require('fs');

	const addSettingsFromFile = filename => {
		const fullName = path.resolve(__dirname, filename);
		if (fs.existsSync(fullName)) {
			Object.assign(config, JSON.parse(fs.readFileSync(fullName, 'utf8').trim()))
		}
	};

	[
		// auth settings
		`../../auth_settings.${DeployEnv}.json`, // on dev machine
		`auth_settings.json`,
		`auth_settings.${DeployEnv}.json`,
		// winston settings, it's now used on server only
		`../winston.${DeployEnv}.json`, // on dev machine
		`winston.json`,
		`winston.${DeployEnv}.json`,
		// version info
		`../version_info.json`, // on dev machine
		`version_info.json`
	].forEach(addSettingsFromFile);

	// if LOG_LEVEL environment variable is set, override winston config log level with it
	if (process.env.LOG_LEVEL && config.winston) {
		config.winston.level = process.env.LOG_LEVEL;
	}

}

//client side override
if (!isServer() && window.__REACT_APP_CONFIG__ !== undefined) {
	config = window.__REACT_APP_CONFIG__;
	config.versionInfo = config.versionInfo || {
			version: { major: "X", minor: "Y", patch: "Z" },
			git: { hash: "" },
			date: ""
	};
}

export default config;
