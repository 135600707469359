/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import ProfileComponent from '../components/Profile';

import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { GET_PUBLIC_PROFILE_WITH_REPORTS } from '../constants/queries';
import { clientOnly } from './ClientOnly';

class PublicProfile extends React.Component {

  toAnswers(slug, sessionUuid) {
		//to generate shareable links
		this.props.history.push(`/report/${sessionUuid}/${slug}`);
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
		const { match: { params } } = this.props;
		const userName = params.userName;
    return (
      <Query
        query={GET_PUBLIC_PROFILE_WITH_REPORTS}
        variables={{ userName }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error) return (<Exception desc={error.message} staticContext={this.props.staticContext} />);
          if (null == data.profile)
            return (<Exception type={404} staticContext={this.props.staticContext} />);

          return (
              <ProfileComponent
                profile={data.profile}
                canEdit={false}
                toAnswers={this.toAnswers.bind(this)}
                goBack={this.goBack}
                loading={loading}
              />
          );
        }}
      </Query>
    );
  }
}

export default clientOnly(PublicProfile);
