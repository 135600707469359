import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, Collapse, Icon, Statistic, Row, Col, Tabs, Checkbox, Breadcrumb } from 'antd';

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';

import { isServer } from '../../utils/ssrUtils';

import CompletedChallenges from '../CompletedChallenges';

import ProfileAvatarEditor from './ProfileAvatarEditor';
import ProfileDescriptionEditor from './ProfileDescriptionEditor';

import styles from './style.less';

const { Panel } = Collapse;
const { Meta } = Card;
const { TabPane } = Tabs;

class Profile extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			changeDescriptionMode: false,
			changeAvatarMode: false, 
		};
	}

	startDescriptionChange = () => {
		this.setState({
			changeDescriptionMode: true
		});
	}
	completeDescriptionChange = (description) => {
		const profileData = {
			description: description,
			showCompletedChallenges: this.props.showCompletedChallenges
		};
		// submit profileData w/o image
		this.props.profileChanged({ profileData });

		this.setState({
			changeDescriptionMode: false,
		});
	}
	cancelDescriptionChange = () => {
		// TODO !!! check
		this.setState({
			changeDescriptionMode: false,
			description: this.props.profile.profileData && this.props.profile.profileData.description, //revert to props value
		});
	}
	
	startAvatarChange = () => {
		this.setState({
			changeAvatarMode: true
		});
	}

	cancelAvatarChange = () => {
		this.setState({
			changeAvatarMode: false
		});
	};
	completeAvatarChange = (resultingImageDataUrl) => {
		
		const image = resultingImageDataUrl.slice(22);  // ignore 'data:image/png;base64,' part
		this.props.profileChanged({ image });

		this.setState({
			changeAvatarMode: false
		});
	};

	dataUrlFromBase64Image = (image) => {
		return image && `data:image/png;base64,${image}`;
	}

	showCompletedChallengesChanged = (e) => {
		const profileData = {
			description: this.props.profile.profileData && this.props.profile.profileData.description,
			showCompletedChallenges: e.target.checked
		};
		// submit profileData w/o image
		this.props.profileChanged({ profileData });
	}

	render() {
		const { profile, loading, canEdit, toPublicProfile, toAnswers, goBack } = this.props;
		const { image } = profile;

		const { changeDescriptionMode, changeAvatarMode	} = this.state;

		const breadcrumbItems = [
			<Breadcrumb.Item key="home">
				<Link to="/">Home</Link>
			</Breadcrumb.Item>,
			<Breadcrumb.Item key='profile'>
				Profile
			</Breadcrumb.Item>
		];


		const statisticsValueStyle = {
			'fontSize': '14px',
			'fontWeight': 'bold'
		}

		const completedChallengesChecked = !profile.profileData 
			|| (profile.profileData
				&& ( (profile.profileData.showCompletedChallenges == undefined) 
					|| !!profile.profileData.showCompletedChallenges));
		const disableButtons = loading || this.state.changeDescriptionMode || this.state.changeAvatarMode;
		return (
			<Card title={
				<Breadcrumb>
					{breadcrumbItems}
				</Breadcrumb>
			}>
				<Meta
					avatar={
						<>
							<Avatar size={128} shape='square' src={this.dataUrlFromBase64Image(image)} icon='user' />
							{canEdit && !changeAvatarMode && !changeDescriptionMode &&
								<div className={styles.subEdit}>
									<Button onClick={this.startAvatarChange} disabled={disableButtons}><Icon type='edit' /> Change</Button>
								</div>
							}
							<Statistic
								title='Member since'
								value={new Intl.DateTimeFormat('en-US', {
									year: 'numeric',
									month: 'long',
									day: '2-digit',
								}).format(Date.parse(profile.dateRegistered))}
								valueStyle={statisticsValueStyle}
								className={styles.statisticsStyle}
							/>
							{/* <Statistic
									title='Authored'
									value={1}
									valueStyle={statisticsValueStyle}
									style={statisticsStyle}
								/> */}
							<Statistic
								title='Completed challenges'
								value={(profile.challengesPassed + profile.challengesFailed)}
								valueStyle={statisticsValueStyle}
								className={styles.statisticsStyle}
							/>
						</>
					}
					title={
						<div>
							<h1 style={{ display: 'inline-block' }} >
								{profile.username}
							</h1>
							{
								/* NOTE we are going to uses this for display name later
								{canEdit && <span style={{margin: '5px', color: 'grey'}}><Icon type='edit' /> Edit username</span>}
								*/
							}
							<span className={styles.floatRight}>
								{canEdit && 
									<Button type="primary" className={styles.controlButton} size="large" 
										onClick={toPublicProfile} disabled={disableButtons}
									>
										<Icon type='eye' /> Public view
									</Button>
								}
								{!isServer() &&
									<>
										<Button className={styles.controlButton} target="_blank" href={`https://www.facebook.com/sharer.php?u=${window.location.origin}/viewprofile/${profile.username}`} icon="facebook" shape="circle" size="large" disabled={disableButtons} />
										<Button className={styles.controlButton} target="_blank" href={`https://twitter.com/share?url=${window.location.origin}/viewprofile/${profile.username}`} icon="twitter" shape="circle" size="large" disabled={disableButtons} />
									</>
								}
							</span>
						</div>
					}
					description={
						<>
							{changeDescriptionMode &&
								<ProfileDescriptionEditor
									description={profile.profileData && profile.profileData.description}
									previewDescriptionChange={this.previewDescriptionChange}
									cancelDescriptionChange={this.cancelDescriptionChange}
									completeDescriptionChange={this.completeDescriptionChange}
								/>
							}
							{changeAvatarMode &&
								<ProfileAvatarEditor
									cancelAvatarChange={this.cancelAvatarChange}
									completeAvatarChange={this.completeAvatarChange}
								/>
							}
							{(!changeDescriptionMode && !changeAvatarMode) &&
								<>
									<ReactMarkdown source={profile.profileData && profile.profileData.description} />
									{canEdit &&
										<div className={styles.subEditBordered}>
											<Button onClick={this.startDescriptionChange} disabled={disableButtons}>
												<Icon type='edit' /> Edit description
											</Button>
										</div>
									}
								</>
							}
						</>
					}
				/>
				{/* showCompletedChallenges not set => show */}
				{(canEdit || (profile.showCompletedChallenges == undefined) || profile.showCompletedChallenges) &&
					<Row gutter={16}>
						<Col span={24}>
							<CompletedChallenges
								data={profile.results}
								toAnswers={toAnswers}
								canShare={false}
							/>
							{
								canEdit && <div className={styles.subEditBordered}>
									<Checkbox style={{ color: 'grey' }} checked={completedChallengesChecked} 
										onChange={this.showCompletedChallengesChanged} disabled={disableButtons}
									>
										Show in my public profile
									</Checkbox>
								</div>
							}
						</Col>
					</Row>
				}
				<Row gutter={16}>
					<Col span={24} className={styles.navButtonBack}>
						<Button type='primary' className={styles.controlButton} onClick={goBack} disabled={disableButtons}>
							<Icon type='double-left' />Back
						</Button>
					</Col>
				</Row>
			</Card>
		);
	}
}

Profile.propTypes = {
	profile: PropTypes.shape({
    image: PropTypes.string,
    profileData: PropTypes.shape({
			description: PropTypes.string,
			showCompletedChallenges: PropTypes.bool
		})
  }).isRequired,
	loading: PropTypes.bool.isRequired
};

export default Profile;
