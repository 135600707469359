/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Query, Mutation } from 'react-apollo';

import { message } from 'antd';

import { isError401 } from '../utils/common';
import ProfileComponent from '../components/Profile';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { GET_PROFILE, UPDATE_USER_PROFILE, GET_PUBLIC_PROFILE_WITH_REPORTS } from '../constants/queries';
import { requireRegisteredUser } from './RequireRegisteredUser';
import { clientOnly } from './ClientOnly';

class Profile extends React.Component {

	constructor(props) {
	super(props);
	this.state = {
		changeDescriptionMode: false,
			changeAvatarMode: false,
	}
	}

	// changeShowCompletedChallenges = (showCompletedChallenges) => {
	// 	this.setState({ showCompletedChallenges });
	// }


	submitProfile = (triggerMutation, userProfile) => {
		if (this.isProfileValid(userProfile)) {
			triggerMutation({
				variables: { userProfile }
			});
		}
	}

	toAnswers = (slug, sessionUuid) => {
		//to generate shareable links
		this.props.history.push(`/report/${sessionUuid}/${slug}`);
	}

	goBack = () => {
		this.props.history.goBack();
	}

	toPublicProfile(userName) {
	this.props.history.push(`/viewprofile/${userName}`);
	}

	isProfileValid = (userProfile) => {
		// TODO !!! implement
		return true;
	}

	setStatusMessage = (data, error) => {
		if (error) {
			message.error("An error has occured, please try to re-upload the image", 20);
		} 

		if (data && !error) {
			message.success("Profile is updated successfully", 5);
		}
	}

	setLoadingMessage = (isLoading) => {
		// set timeout to avoid a warning about render containing an update
		setTimeout(() => {
		if (isLoading && !this.hideProgress) {
			this.hideProgress = message.loading('Action in progress..', 0);
		}

		if (!isLoading && this.hideProgress) {
			this.hideProgress();
			this.hideProgress = null;
		}
		},0);
	}

	render() {
		const {user, isLoadingUser, userError, history, match: { params } } = this.props;
		/* NOTE: we need userId to form link to shareable achievements page */
		const userId = user.profile.sub;
		const userName = user.profile.name;

	return (
		<Query
		query={GET_PROFILE}
		>
		{({ loading, error, data }) => {
			if (loading)
			return (
				<aside>
				<LoadingIndicator />
				</aside>
			);
			
			if (error) {
				// FIXME this is a quick fix, taking into account our plans to ditch GraphQL altogether
				const is401 = isError401(error);
				return (<Exception desc={is401 ? 'Unathorized' : error.message} type={is401 ? 401 : undefined} staticContext={this.props.staticContext} />);
			}
			if (!data || !data.profile)
			return (<Exception type={404} staticContext={this.props.staticContext} />);

					let userProfile = data.profile;

			return (
						<Mutation
							mutation={UPDATE_USER_PROFILE}
							onCompleted={data => this.setStatusMessage(data, null)}
							onError={error => this.setStatusMessage(null, error)}
							//fix for issue #413
							//can't use client.cache.writeQuery cause returned value does not fit for public profile
							refetchQueries={[{
    							query: GET_PUBLIC_PROFILE_WITH_REPORTS,
    							variables: { userName },
  								}]}
							>
							{(triggerSubmitProfileMutation, { data, loading, error } ) => {
								if(data && data.updateUserProfile) {
									userProfile = data.updateUserProfile;
								}
								return <>
									{this.setLoadingMessage(loading)}
									<ProfileComponent
										profile={userProfile}
										profileChanged={this.submitProfile.bind(this, triggerSubmitProfileMutation)}
										loading={loading}
										canEdit={true}
										toPublicProfile={this.toPublicProfile.bind(this, userName)}
										toAnswers={this.toAnswers}
										goBack={this.goBack}
									/>
								</>;
						}}
						</Mutation>
			);
		}}
		</Query>
	);
	}
}

export default clientOnly(requireRegisteredUser(Profile));
