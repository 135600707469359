
export function getCurrentQuestionSlug(challengeSlug) {
    return localStorage.getItem(challengeSlug);
}

export function setCurrentQuestionSlug(challengeSlug, questionSlug) {
    localStorage.setItem(challengeSlug, questionSlug);
}

export function resetCurrentQuestionSlug(challengeSlug) {
    localStorage.removeItem(challengeSlug);
}

export function getLocalAnswers(challengeSlug) {
  const key = `answers-${challengeSlug}`;
  const json = localStorage.getItem(key);
  return json ? JSON.parse(json) : [];  
}
  
export function addLocalAnswer(challengeSlug, answer) {
  const answers = getLocalAnswers(challengeSlug);
  const idx = answers.findIndex(a => a.questionUuid == answer.questionUuid);
  if (idx == -1)
    answers.push(answer);
  else 
    answers[idx] = answer;
  const key = `answers-${challengeSlug}`;
  localStorage.setItem(key, JSON.stringify(answers));
}

export function resetLocalAnswers(challengeSlug) {
  const key = `answers-${challengeSlug}`;
  localStorage.removeItem(key);
}

export function getTestLocalAnswers() {
  const key = `testAnswers`;
  const json = localStorage.getItem(key);
  return json ? JSON.parse(json) : [];  
}

export function addTestLocalAnswer(answer) {
  const answers = getTestLocalAnswers();
  const idx = answers.findIndex(a => a.question.uuid == answer.question.uuid);
  if (idx == -1)
    answers.push(answer);
  else 
    answers[idx] = answer;
  const key = 'testAnswers';
  localStorage.setItem(key, JSON.stringify(answers));
}

export function resetTestLocalAnswers() {
  const key = 'testAnswers';
  localStorage.removeItem(key);
}
