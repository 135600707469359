/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from "react";
import { Query } from 'react-apollo';

import Exception from "../components/Exception";
import LoadingIndicator from "../components/LoadingIndicator";
import IntroComponent from "../components/Intro";

import config from "../utils/config";
import { GET_INTRO_FOR_CHALLENGE } from '../constants/queries';
import { TEST_CHALLENGE_SLUG } from "../constants/testConstants";

const TestIntroContainer = (props) => {
  const { params } = props.match;

  const toHome = () => {
    props.history.push("/");
  };

  return (
    <Query
      query={GET_INTRO_FOR_CHALLENGE}
      variables={{
        challengeSlug: TEST_CHALLENGE_SLUG,
      }}
    >
      {({ loading, data, error }) => {
        const startQuestions = () => {
          props.history.push('/test-your-english-language-cefr-level/questions');
        };

        if (loading) {
          return <LoadingIndicator />;
        }

        if (error) {
          return <Exception type={500} />;
        }

        const challengeData = data.challengeBySlug;

        // show intro
        return (
          <IntroComponent
            taxonomy={props.taxonomy}
            challengeData={challengeData}
            startChallenge={startQuestions}
            viewAnswers={null}
            onExit={toHome}
            path="/test-your-english-language-cefr-level"
            site={config.webUrl}
          />
        );
      }}
    </Query>
  );
};

export default TestIntroContainer;
