import React, { Component } from 'react';
import { Layout, Row, Col, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import LoginInfo from '../LoginInfo';
import MainMenu from '../MainMenu';
import UserClaims from '../UserClaims';


import styles from './style.less';

const { Header } = Layout;

export default class GlobalHeader extends Component {
  render() {
    const { logo, logoSmall, versionInfo: vi, forumUrl, isMobile } = this.props;
		const versionString = vi ? `v${vi.version.major}.${vi.version.minor}.${vi.version.patch} | ${vi.git.hash} | ${vi.date}` : null;
		const betaSup = <sup className={styles.beta}>&nbsp;beta</sup>;
		const beta = versionString ? <Tooltip title={versionString}>{betaSup}</Tooltip> : betaSup;
    return (
      <Header className={styles.header}>
        { (
          <Row justify="center">
            <Col xs={0} sm={0} md={0} lg={0} xl={2} xxl={3}>
              <div className={styles.logoImageFrame}>
                <div>
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col className={`${styles.logoTitleCol}`} xs={24} sm={24} md={24} lg={24} xl={20} xxl={19}>
              <div>
                <div>
                  <Link to="/" className={`${styles.logoLink} ${styles.logoText}`}>
                    <img src={logoSmall} alt="logo" className={styles.logoImageSmall} />
                    <span className={styles.titleStyle}>Grammar</span> <span className={styles.titleHeaderRed}>Error</span>
  								  {beta}
                  </Link>
                  {<MainMenu isMobile={isMobile} /> }
                  <div className={styles.menuLinks}>
                    <LoginInfo history={this.props.history} />
                  </div>
                  <div className={styles.motto}>All the grammar quiz and more!</div>
                </div>
              </div>
              {/* { forumUrl && (
                <div className={styles.mainLinks}><a href={forumUrl} target="blank">Forum</a></div>
              ) } */}
               {/* <UserClaims /> */}
            </Col>
          </Row>
        ) }
      </Header>
    );
  }
}
