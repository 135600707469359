/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import PageTitle from '../components/PageTitle';
import IntroComponent from '../components/Intro';
import EmbeddedIntroComponent from '../components/EmbeddedIntro';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import config from '../utils/config';

import { GET_INTRO_FOR_CHALLENGE } from '../constants/queries';
import { isServer } from '../utils/ssrUtils';

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.startChallenge = this.startChallenge.bind(this);
    this.viewAnswers = this.viewAnswers.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  startChallenge() {
    this.props.history.push(`${this.props.embedded ? '/embed' : ''}/challenge/${this.props.match.params.challengeSlug}`);
  }

  viewAnswers() {
    this.props.history.push(`/correctanswers/${this.props.match.params.challengeSlug}`);
  }

  onExit() {
    this.props.history.push('/challenges');
  }

  render() {
    const { staticContext } = this.props;
    const { params } = this.props.match;

    return (
      <Query
        query={GET_INTRO_FOR_CHALLENGE}
        variables={{
          challengeSlug: params.challengeSlug,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error)
            return (<Exception desc={error.message} staticContext={staticContext} />);
          if (null == data.challengeBySlug)
            return (<Exception type={404} staticContext={staticContext} />);

          const challengeData = data.challengeBySlug;

          if (staticContext) {
            staticContext.pageTitle = challengeData.title;
            staticContext.keywords = challengeData.tags.map(t => t.title);
          }

          const intro = this.props.embedded ? (<EmbeddedIntroComponent
              taxonomy={this.props.taxonomy}
              challengeData={challengeData}
              startChallenge={this.startChallenge}
              embedded
            />) : (<IntroComponent
              taxonomy={this.props.taxonomy}
              challengeData={challengeData}
              startChallenge={this.startChallenge}
              viewAnswers={this.viewAnswers}
              onExit={this.onExit}
              /* Nick: quick fix for location not being available during SSR */
              path={!isServer() && location.pathname}
              site={config.webUrl}
            />);

          return (
            <>
              { intro }
              <PageTitle pageTitle={challengeData.title} />
            </>
          );
        }}
      </Query>
    );
  }
}

export default Intro;
