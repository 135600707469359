// NOTE this component is intentionally made as stupid as possible to avoid overengineering, later we might employ more general solution #225

import React, { Component } from 'react';
import { Card, Button, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import styles from './style.less';


class LegalPageComponent extends Component {

  constructor(props) {
    super(props);
  }

  onAcceptAndGoBack = () => {
    this.props.history.goBack();
  }

  onAcceptAndonExit = () => {
    this.props.history.push('/challenges');
  }

  onRejectAndLeave = () => {
    window.location = 'about:blank';
  }

  render() {

		const {legalData, ru} = this.props;

    const aabText = ru ? 'Принять и вернуться' : 'Accept and Go Back';
    const aacText = ru ? 'Принять и перейти к тестам' : 'Accept and Go to Challenges';
    const ralText = ru ? 'Не принимать и покинуть сайт' : 'Reject and Leave';

		const breadcrumbItems = [(
			<Breadcrumb.Item key="home">
				<Link to="/">Home</Link>
			</Breadcrumb.Item>
		)];

    return (
      <Card bordered={false} >
				<Breadcrumb>{breadcrumbItems}</Breadcrumb>
        <ReactMarkdown source={legalData.markdown} />
        <Button type="primary" size="large" className={styles.controlButton} onClick={this.onAcceptAndGoBack}>{aabText}</Button>
        <Button type="primary" size="large" className={styles.controlButton} onClick={this.onAcceptAndonExit}>{aacText}</Button>
        <Button type="danger" size="large" className={styles.controlButton} onClick={this.onRejectAndLeave}>{ralText}</Button>
      </Card>
    );
  }
}

export default LegalPageComponent;
