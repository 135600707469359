import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { isServer } from '../../utils/ssrUtils';

function PageTitle( { pageTitle, siteTitle } ) {

    const getFullTitle = () => {
        let fullTitle;
        if(pageTitle) {
            fullTitle = `${pageTitle} - ${siteTitle}`;
        } else {
            fullTitle = siteTitle;
        }
        return fullTitle;
    }

    useEffect(() => {
        if (!isServer()) {
            const titleEl = document.getElementsByTagName("title")[0];
            titleEl.text = getFullTitle();
        }
    }, []);

    if (isServer())
        return null;

    return <></>;
}

PageTitle.defaultProps = {
    siteTitle: 'GrammarError',
}

export default PageTitle;