import React from 'react';
import { Icon, Button } from 'antd';

import { getClientLogger } from '../../utils/clientLogger';

/*
react avatar editors:
	https://github.com/mosch/react-avatar-editor
	484 commits, 1377 stars, last activity 02.04.2019

	https://github.com/kirill3333/react-avatar
	105 commits, 95 stars, last activity 10.03.2019

	there are no more, so we can take the first one as more mature
	example of usage
	https://github.com/mosch/react-avatar-editor/blob/master/docs/App.jsx
 */

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import styles from './style.less';

class ProfileAvatarEditor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scale: 2,
			borderRadius: 0,
			image: null,
			previewImage: null
		};
		getClientLogger();
	}

	loadAvatarImage = (image) => {
		this.setState({ image });
	};
	previewAvatarChange = (previewImage) => {
		this.setState({ previewImage });
	};

	setEditorRef = (editor) => {
		if (editor) this.editor = editor;
	}

	onImageChange = () => {
		const img = this.editor.getImageScaledToCanvas().toDataURL()
		this.previewAvatarChange(img);
	}

	handleScale = e => {
		const scale = parseFloat(e.target.value)
		this.setState({ scale })
	}

	render() {
		const {
			cancelAvatarChange,
			completeAvatarChange,
		} = this.props;

		return (
			<div className={styles.centered}>
				<Dropzone
					multiple={false}
					onDrop={acceptedFiles => {
						const reader = new FileReader()
						reader.onabort = () => Log.info('file reading was aborted')
						reader.onerror = () => Log.info('file reading has failed')
						reader.onload = () => {
							const base64str = reader.result;
							this.loadAvatarImage(base64str);
						}

						acceptedFiles.forEach(file => reader.readAsDataURL(file))
					}}>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							{this.state.image
								? <AvatarEditor
									ref={this.setEditorRef}
									scale={parseFloat(this.state.scale)}
									image={this.state.image}
									border={50}
									color={[255, 255, 255, 0.6]} // RGBA
									onClick={e => e.stopPropagation()}
									onImageChange={this.onImageChange}
									onImageReady={this.onImageChange}
									className={styles.centered}
								/>
								: <div>
									<Icon type="upload" className={styles.bigIcon} />
									<p>Drag'n'drop image here, or click to select image</p>
								</div>
							}
						</div>
					)}
				</Dropzone>
				<input
					name="scale"
					type="range"
					onChange={this.handleScale}
					min={'0.1'}
					max="4"
					step="0.01"
					defaultValue="2"
				/>
				<div className={styles.subEditBordered}>
					<span className={styles.buttonWrap} >
						<Button onClick={() => completeAvatarChange(this.state.previewImage)} disabled={!this.state.previewImage}>
							<Icon type='check' /> Save
						</Button>
					</span>
					<span>
						<Button onClick={cancelAvatarChange}>
							<Icon type='close' /> Cancel
						</Button>
					</span>
				</div>
			</div>
		);
	}
}

export default ProfileAvatarEditor;
