import React from 'react';
import PropTypes from 'prop-types';

import { isServer } from '../../utils/ssrUtils';
import { getClientLogger } from '../../utils/clientLogger';
import { getLoginManager } from '../../utils/loginManager';
import CallbackComponent from './Callback';
import LoadingIndicator from '../LoadingIndicator';

class CallbackPageComponent extends React.Component {

	constructor(props) {
		super(props);

		// are we on server?
		if (!isServer()) {
			getClientLogger(); // this inits window.Log
			this.loginManager = getLoginManager();
		}
	}

	successCallback = (user) => {
		Log.info("Redirected to redirect_url after successful login, user.state: " + JSON.stringify(user.state));

		// we don't really save all user, it's UserManager's task, but we save email to know if the user is registered
		this.loginManager.signInCallback(user);

		if (user.state && user.state.returnUrl) {
			this.props.history.push(user.state.returnUrl);
		} else {
			this.props.history.push('/');
		}
	}
	errorCallback = (error) => {
		Log.info("Redirected to redirect_url after login error: " + JSON.stringify(error));

		this.loginManager.signInErrorCallback(error);

		if (error.state && error.state.returnUrl) {
			this.props.history.push(error.state.returnUrl);
		} else {
			this.props.history.push('/');
		}
	}

	render() {
		if (isServer()) return false;

		return (
			<CallbackComponent
				userManager={this.loginManager.getUserManager()}
				successCallback={this.successCallback}
				errorCallback={this.errorCallback}
			>
				<LoadingIndicator text="Redirecting..." />
			</CallbackComponent>
		);
	}
}

CallbackPageComponent.propTypes = {
	//history: PropTypes.isRequired,
};

export { CallbackPageComponent };
export default CallbackPageComponent;
