// TODO !!! move to containers
import React from 'react';
import ReactDOM from 'react-dom';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { GET_CHALLENGE_LIST, GET_CHALLENGE_LIST_BY_TAG } from '../constants/queries';

import { ensureUser } from './EnsureUser';

const challengeListQuerySelector = (props) => {
	const { tagSlug } = props;

  const query = tagSlug ? GET_CHALLENGE_LIST_BY_TAG : GET_CHALLENGE_LIST;
	// here user is loaded and is an object or null
	const vars = tagSlug 
		? {
				tagSlug,
				offset: 0,
				limit: props.limit,
			}
		: {
				offset: 0,
				limit: props.limit,
			};

  return (
    <Query
      query={query}
			variables={vars}
			ssr={false}
    >
      {(apollo) => props.children(apollo)}
    </Query>
  );
};

export default ensureUser(challengeListQuerySelector);