
function isError401(error) {
	return error && (-1 != error.toString().indexOf('401'));
}

function extractSessionId(error) {
	let regexp = /sessionid\(([^\)]+)\)/;
	if (!error)
		return false;
	let arr = [...error.toString().match(regexp)];
	console.log(arr);
	return !!arr[1] && arr[1];
}

export { isError401, extractSessionId }