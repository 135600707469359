/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Button, Progress, Card } from 'antd';
import { Link } from 'react-router-dom';

import { Answer, QuestionDisplay } from '@kindrobots/web.fe.ui';

import TaxonomyTags from '../TaxonomyTags';

import MetaInfo from '../MetaInfo';

import * as TestType from '../../constants/testType';

import styles from './style.less';

let lastHeight = -1;

class EmbeddedQuestionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.frameRef = React.createRef();
    this.state = {
      answer: {
        answerElements: [],
      },
      showValidationMessage: false,
    };
  }

  componentDidMount() {
    this.updateFrameHeight();
  }

  componentDidUpdate() {
    this.updateFrameHeight();
  }

  updateFrameHeight() {
    if (lastHeight != this.frameRef.current.scrollHeight && window.parent) {
      window.parent.postMessage({ id: "gmsh", h: this.frameRef.current.scrollHeight }, '*');
      lastHeight = this.frameRef.current.scrollHeight;
    }
  }

  render() {
    const { question, onExit, showDate = false, testType } = this.props;
    const last = !question.nextQuestionSlug;
    const isTest = testType && (testType === TestType.CEFR_LEVEL_TEST);

    const onSetAnswer = (e) => {
      const answer = new Answer({question, answerElements: this.state.answer.answerElements});
      answer.setAnswer(e);
      this.setState({
        answer,
        showValidationMessage: false
      });
    };

    const childprops = {
          question,
          answer: this.state.answer,
          onSetAnswer,
          showValidationMessage: this.state.showValidationMessage,
          mutating: this.props.mutating,
        };

    //validation of answer does not depend on UI and can be calculated from props, so I leave it here
    const isComplete = this.state.answer.answerElements.length >= question.requiredAnswers;

    const onSubmit = () => {
      if (isComplete) {
        this.props.submitAnswer(this.state.answer);
      } else {
        this.setState({ showValidationMessage: true });
      }
    }

    const percentage = Math.round(100*(question.currentQuestionNumber-1)/question.questionsTotalNumber);

    return (
      <div ref={this.frameRef} className={styles.frame}>
        <div className={styles.questionBox}>
          <Progress percent={percentage} strokeWidth={16} />
          <QuestionDisplay {...childprops} />
        </div>
        { this.props.taxonomy && !isTest && <TaxonomyTags tags={question && question.tags ? question.tags : []} taxonomy={this.props.taxonomy} /> }
        <div>
            <Button loading={this.props.mutating} type="primary" size="large" className={styles.controlButton} onClick={onSubmit}>{ !last ? 'Submit Answer' : 'Finish' }</Button>
            { onExit && <Button disabled={this.props.mutating} size="large" className={styles.controlButton} onClick={onExit} type="danger">Exit</Button> }
        </div>
        <MetaInfo {...question} showDate={showDate} />
      </div>
    );
  }
}

export default EmbeddedQuestionComponent;
