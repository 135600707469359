import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  Button, Card, Icon, Row, Col, Breadcrumb, Checkbox
} from 'antd';
import { Nav, buildReturnPath } from '../Nav';
import ProfileDeletionConfirmationDialog from './ProfileDeletionConfirmationDialog';

import styles from './style.less';

const { Meta } = Card;

const Section = (props) => {
  const { label } = props;
  const { content } = props;
  return (
    <div style={{ margin: '14px 0 14px 0' }}>
      <span
        style={{
          minWidth: '150px',
          display: 'inline-block',
          verticalAlign: 'top',
        }}
      >
        {label}
      </span>
      <span style={{ minWidth: '150px', display: 'inline-block' }}>
        {content}
      </span>
    </div>
  );
};

const Separator = () => (
  <div style={{ borderTop: '1px solid lightgray', height: '1px' }}>&nbsp;</div>
);

const notificationOptions = [
  { value: 'changesToPlatform', label: 'Major platform updates' },
  { value: 'newChallenges', label: 'New challenges (monthly)' },
  { value: 'newsForContentAuthors', label: 'News for content authors' },
];

const notificationSettingsToValues = settings => settings
  && Object.keys(settings).filter(k => k !== '__typename' && settings[k]);

const notificationValuesToSettings = (values) => {
  if (!values) return null;
  const s = {};
  values.forEach((v) => {
    s[v] = true;
  });
  return s;
};

const Settings = ({
  settings,
  settingsChanged,
  goBack,
  isLoading /* FIXME !!! show spinner when loading */,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showProfileDeletionDialog = () => {
    setIsModalVisible(true);
  };

  const profileDeletionOK = () => {
    setIsModalVisible(false);
    settingsChanged({ deleted: true });
  };

  const profileDeletionCancel = () => {
    setIsModalVisible(false);
  };

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="settings">Settings</Breadcrumb.Item>,
  ];

  return (
    <>
      <ProfileDeletionConfirmationDialog
        onOK={profileDeletionOK}
        onCancel={profileDeletionCancel}
        visible={isModalVisible}
      />
      <Card title={<Breadcrumb>{breadcrumbItems}</Breadcrumb>}>
        <Meta
          title={(
            <div>
              <h1>Settings</h1>
            </div>
          )}
        />

        <Section
          label="Username"
          content={(
            <>
              {settings.username}
              <Nav.ChangeUsernameLink
                returnPath={buildReturnPath(window)}
                style={{ marginLeft: '24px' }}
              >
                <Button type="primary" disabled={isLoading}>
                  <Icon type="user" />
                  {' '}
                  Change username
                </Button>
              </Nav.ChangeUsernameLink>
            </>
)}
        />

        <Section label="Email" content={settings.email} />
        <Separator />

        {settings.isExternal ? (
          <Section
            label="External User"
            content={<Icon type={settings.externalProviderID.toLowerCase()} />}
          />
        ) : (
          <Section
            label="Pasword"
            content={(
              <Nav.ChangePasswordLink returnPath={buildReturnPath(window)}>
                <Button type="primary" disabled={isLoading}>
                  <Icon type="lock" />
                  {' '}
                  Change Password
                </Button>
              </Nav.ChangePasswordLink>
)}
          />
        )}
        <Separator />

        <Section
          label="Notifications"
          content={(
            <div>
              <Checkbox.Group
                options={notificationOptions}
                value={notificationSettingsToValues(
                  settings.notificationSettings
                )}
                onChange={values => settingsChanged(notificationValuesToSettings(values))
                }
                name="notificationSettings"
                disabled={isLoading}
                className={styles.notificationsCheckboxGroup}
              />
            </div>
)}
        />
        <Separator />

        <Section
          label="Delete Account"
          content={(
            <div>
              <Button
                type="danger"
                disabled={isLoading}
                onClick={showProfileDeletionDialog}
              >
                <Icon type="DeleteOutlined" />
                {' '}
                Delete My Account and Data
              </Button>
            </div>
)}
        />

        <Row gutter={16}>
          <Col span={24} className={styles.navButtonBack}>
            <Button
              type="primary"
              className={styles.controlButton}
              onClick={goBack}
              disabled={isLoading}
            >
              <Icon type="double-left" />
              Back
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

Settings.propTypes = {
  settings: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  settingsChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Settings;
