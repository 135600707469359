import React, { useState } from 'react';
import { Row, Col, Button, Card, Tag, Modal } from 'antd';
import ReactDOM from 'react-dom';

import EmbeddedIntro from '../EmbeddedIntro';
import TaxonomyTagsCard from '../TaxonomyTagsCard';
import EmbeddedComments from '../EmbeddedComments';
import EmbedDialog from '../EmbedDialog';

import * as TestType from '../../constants/testType';

import styles from './style.less';

const IntroComponent = ({
  challengeData,
  taxonomy,
  startChallenge,
  viewAnswers,
  onExit,
  site,
  path
}) => {
  const testType = challengeData.testType;
  const isTest = testType && (testType === TestType.CEFR_LEVEL_TEST);

  const actions = (
    <div>
        <Button className={styles.controlButton} size='large' onClick={onExit}>
          Back
        </Button>
      {
        /* Nick: TODO we need to split components further */
        (!isTest)
          && (
          <Button className={styles.controlButton} size='large' onClick={viewAnswers}>
            View Answers
          </Button>
          )
      }
      <Button className={styles.controlButton} size='large' type="primary" onClick={startChallenge}>
        Start
      </Button>
    </div>
  );

  const [isEmbed, setEmbed] = useState(false);

  /* Nick: quick fix for location not being available during SSR */
  let parts; let sharepath;
  if (path) {
    parts = path.split('/');
    parts.length = 3; //drop question slug
    sharepath = parts.join('/');
  }

  return (
<>
    <Card className={`${styles.card} ${styles.desc}`} title={(
        <h3 className={styles.cardTitle}>{challengeData.title}</h3>
      )}
      extra={
        /* Nick: quick fix for location not being available during SSR */
        path && <><Button className={styles.shareButton} target="_blank" href={`https://www.facebook.com/sharer.php?u=${site}${sharepath}`} icon="facebook" shape="circle" size="small" />
          <Button className={styles.shareButton} target="_blank" href={`https://twitter.com/share?url=${site}${sharepath}`} icon="twitter" shape="circle" size="small" />
          {!isTest && (<Button shape="round" type="primary" size="small" onClick={() =>  setEmbed(true)}>Embed</Button>)}
        </>}
    >
      <EmbeddedIntro
        challengeData={challengeData}
        taxonomy={taxonomy}
        startChallenge={startChallenge}
        embedded={false}
        showTitle={false}
        showDate={!isTest}
        actions={actions}
      />
    </Card>
    {
      /* Nick: TODO we need to split components further */
      !isTest
        && (
        /* Nick: TODO we need to split components further */
        <EmbeddedComments path={`/intro/${challengeData.slug}`} />
        )
    }
		<TaxonomyTagsCard tags={ (challengeData && challengeData.tags) ? challengeData.tags : []} taxonomy={taxonomy} />
    
    {
      /* Nick: quick fix for location not being available during SSR */
      path && 
      <EmbedDialog site={site} path={path} isIntro={true} isVisible={isEmbed} close={() => setEmbed(false)} />
    }
	</>
);
};

export default IntroComponent;
