import React from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import Routes from './routes';

import { Taxonomy } from './api/taxonomy';

import Exception from './components/Exception';
import LoadingIndicator from './components/LoadingIndicator';
import LoginProvider from './containers/LoginProvider';

export default class App extends React.Component {
  render() {
    const query = gql`{
      tags {
        uuid
        slug
        title
        description
        parentSlug
        license
        descriptionUrls
      }
    }`;

    return (
    <Query query={query}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <LoadingIndicator />
          );
        if (error)
          return (
            <Exception desc={error.message} staticContext={this.props.staticContext} />
          );
        const normalizedTags = data.tags.map(t => ({
                id: t.uuid,
                title: t.title,
                description: t.description,
                value: t.slug,
                key: t.slug,
                parentKey: t.parentSlug,
                license: t.license,
                descriptionUrls: t.descriptionUrls
              }));
				const taxonomy = new Taxonomy(normalizedTags);

				return (
    	    <ConfigProvider locale={enUS}>
						<LoginProvider>{/* FIXME !!! should we pass loginManager here instead of subscribing to it? */}
    	      	<Routes taxonomy={taxonomy} />
						</LoginProvider>
    	    </ConfigProvider>
      	);
      }
    }
    </Query>
    );
  }
}
