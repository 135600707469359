// This module is intended to provide more helpful errors with callstacks 
// for the cases when browser objects like window are used on server



let server = null;
const isServer = () => {
	return server || (server = ! (typeof window != 'undefined' && window.document));
}

const defineThrowingGetter = (target, propertyName) => {
	Object.defineProperty(target, propertyName, {get : function(){ throw new Error(`SSR: accessing ${propertyName}`) }});
}

const safeGuardGlobalObject = (name) => {
	if(isServer()) defineThrowingGetter(module.exports, name);
	else { // browser
		if('window' === name) module.exports.window = window;
		else module.exports[name] = window[name];
	}
}

// these can be used like this
// import { window, localStorage, sessionStorage }
safeGuardGlobalObject('window'); 
safeGuardGlobalObject('localStorage'); 
safeGuardGlobalObject('sessionStorage');
// etc

module.exports.isServer = isServer;
