import React from 'react';
import { Row, Col, Button, Card, Icon, Progress } from 'antd';
import SharePanel from '../SharePanel';
import LeaderboardHigh from '../Banners/LeaderboardHigh';
import UserBanner from '../Banners/UserBanner';

import styles from './style.less';

const { Meta } = Card;

let lastHeight = -1;

class EmbeddedReportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.frameRef = React.createRef();
  }

  componentDidMount() {
    this.updateFrameHeight();
  }

  componentDidUpdate() {
    this.updateFrameHeight();
  }

  updateFrameHeight() {
    if (lastHeight != this.frameRef.current.scrollHeight && window.parent) {
      window.parent.postMessage({ id: "gmsh", h: this.frameRef.current.scrollHeight }, '*');
      lastHeight = this.frameRef.current.scrollHeight;
    }
  }

  render() {
    const { report, onExit, toAnswers, toProfile, } = this.props;
    const actions = (
      <div>
        <Button className={styles.standard} size='large' type="primary" onClick={onExit}>
          To Challenges
        </Button>
        <Button className={styles.standard} size='large' onClick={toAnswers}>
          View Explanations
        </Button>
        { toProfile && (
        <Button className={styles.standard} size='large' onClick={toProfile}>
          To Profile
        </Button>
        )}
      </div>
    );
    const iconMap = {
      error: <Icon className={styles.ierror} type="close-circle" />,
      success: <Icon className={styles.isuccess} type="check-circle" />,
    };
    const titleMap = {
      error: 'Challenge failed',
      success: 'Challenge passed',
    };
    const title = report.passed ? "Challenge passed" : "Challenge failed";
    const resultType = report.passed ? "success" : "error";
    return (
      <div ref={this.frameRef} className={styles.frame}>
        <div className={styles.resultIcon}>{iconMap[resultType]}</div>
        <h1>{titleMap[resultType]}</h1>
        <div className={styles.reportBox}>
          <SharePanel/>
          <Progress percent={Math.round(100*report.nCorrect/report.nQuestions)} format={percent => percent + '%'} strokeWidth={16} />
        </div>
        { report.challengeBanners && report.challengeBanners.length ? <UserBanner challengeBanners={report.challengeBanners} /> : <LeaderboardHigh /> }
        Press button to go back to challenges or view answers with explanations
        <div className={styles.actions}>{actions}</div>
      </div>
    );
  }
}

export default EmbeddedReportComponent;
