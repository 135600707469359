/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { getLoginManager } from '../utils/loginManager';
import ReportComponent from '../components/Report';
import EmbeddedReportComponent from '../components/EmbeddedReport';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { isServer } from '../utils/ssrUtils';

// FIXME !!! this one is broken, #362, #353, #208 (the whole component)

export const SESSION_REPORT_FIELDS = `
		id
		uuid
		challengeUuid
		challengeTitle
		challengeAnsweringSessionUuid
		nQuestions
		nCorrect
		passed
		answersMap
		challengeBanners {
			type
			bannerPath
			bannerTarget
		}
`;

const GET_SESSION_REPORT_BY_UUID = gql`query ($sessionUuid: ID!) {
	challengeAnsweringSessionReportBySessionUuid(sessionUuid: $sessionUuid) {
		${SESSION_REPORT_FIELDS}
	}
}`;

function hasUser(){
    if (isServer()) return false;
    else {
    	return getLoginManager().isUserRegistered();
    }
}

class Report extends React.Component {

  	constructor(props) {
    	super(props);
    	this.state = {
      		hasUser: hasUser()
    	}
	}

	onExit() {
		if (this.props.embedded)
		 	window.open('/challenges', '_blank');
		else
			this.props.history.push('/challenges');
	}

	toProfile() {
		if (this.props.embedded)
   		 	window.open('/profile', '_blank');
   		else
			this.props.history.push('/profile');
	}

	toAnswers(sessionUuid) {
		//sessionUuid is needed to generate shareable links
		if (this.props.embedded)
		 	window.open(`/answers/${sessionUuid}/${this.props.match.params.challengeSlug}`, '_blank');
		else
			this.props.history.push(`/answers/${sessionUuid}/${this.props.match.params.challengeSlug}`);
	}

	render() {
		const { params } = this.props.match;
		return (
			<Query
				query={GET_SESSION_REPORT_BY_UUID}
				variables={{
					sessionUuid: params.session
				}}
			>
				{({ loading, error, data }) => {
					if (loading)
						return (
							<aside>
								<LoadingIndicator />
							</aside>
						);
					if (error)
						return (<Exception desc={error.message} staticContext={this.props.staticContext} />);
					const results = data.challengeAnsweringSessionReportBySessionUuid;
					if (null == results)
						return (<Exception type={404} staticContext={this.props.staticContext} />);

					return this.props.embedded ?
						(<EmbeddedReportComponent
							report={results}
							onExit={this.onExit.bind(this)}
							toAnswers={this.toAnswers.bind(this, results.challengeAnsweringSessionUuid)}
							toProfile={this.state.hasUser ? this.toProfile.bind(this) : null }
						/>)
						: (<ReportComponent
							report={results}
							onExit={this.onExit.bind(this)}
							toAnswers={this.toAnswers.bind(this, results.challengeAnsweringSessionUuid)}
							toProfile={this.state.hasUser ? this.toProfile.bind(this) : null }
						/>);
				}}
			</Query>
		);
	}
}

export default Report;
