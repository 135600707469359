import React from 'react';
import { Spin } from 'antd';

import styles from './style.less';

const LoadingIndicator = ({text}) => {
  return (
	<div className={styles.loadingCard}>
		<Spin size="large" />&nbsp;{text || "Loading..."}
	</div>
  );
};

export default LoadingIndicator;
