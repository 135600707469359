/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, {lazy, Suspense} from 'react';

import { Query } from 'react-apollo';

import PageTitle from '../components/PageTitle';
import ArticlePageComponent from '../components/ArticlePage';
import EmbeddedComments from '../components/EmbeddedComments';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { GET_ARTICLE } from '../constants/queries';

class ArticlePage extends React.Component {
  render() {
    const { staticContext } = this.props;
    const { params } = this.props.match;

    return (
      <Query
        query={GET_ARTICLE}
        variables={{
          articleSlug: params.articleSlug
        }}
      >
        {({ loading, error, data, client }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error)
            return (<Exception desc={error.message} staticContext={staticContext} />);
          if (null == data.articleBySlug)
            return (<Exception type={404} staticContext={staticContext} />);

          const articleData = data.articleBySlug;

          if (staticContext && articleData.title) {
            staticContext.pageTitle = articleData.title;
          }

          return (<>
            <ArticlePageComponent
							articleData={articleData}
            />
            <EmbeddedComments path={`/article/${articleData.slug}`} />
            <PageTitle pageTitle={articleData.title} />
          </>);
        }}
      </Query>
    );
  }
}

export default ArticlePage;
