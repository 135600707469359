import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';

class ErrorMessageComponent extends Component {
  componentDidMount() {
    const { error } = this.props;
    if (error && error.message) message.error(error.message);
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error && error.message) message.error(error.message);
  }

  // FIXME check if this component ever re-renders on props update
  render() {
    return <div />;
  }
}

ErrorMessageComponent.defaultProps = {
  error: null,
};

ErrorMessageComponent.propTypes = {
  error: PropTypes.shape({
    errorMessage: PropTypes.string
  }),
};


export default ErrorMessageComponent;
