import React, { useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';

import styles from './style.less';

const EmbedDialog = ({ site, path, isIntro, isVisible, close }) => {
    const [embedIntro, setEmbedIntro] = useState(isIntro);
    const parts = path.split('/');
    parts[1] = embedIntro ? 'intro' : 'challenge'; 
    const slug = parts[2]; //grab challenge slug
    parts.length = 3; //drop question slug
    const sharepath = parts.join('/');
    const iframeCode = `<iframe id="${slug}"
    name="${slug}"
    src="${site}/embed${sharepath}"
    style="width: 100%; border: 0px; overflow: scroll;"></iframe>
    <script>function _hm(e) { if (e.data && e.data.id == \'gmsh\') window.document.getElementById("${slug}").style.height = e.data.h + \'px\'; };window.addEventListener(\'message\', _hm);</script>`;
    return (
            <Modal title="Embed the Challenge" visible={isVisible} onCancel={close} footer={[<Button type="primary" onClick={() =>  navigator.clipboard.writeText(iframeCode)}>Copy</Button>]}>
                <p>Share the challenge by embedding it on your website or blog</p>
                <p><Checkbox checked={embedIntro} onChange={e => setEmbedIntro(e.target.checked)}>Embed intro</Checkbox></p>
                <div className={styles.iframeCode}>{iframeCode}</div>
            </Modal>
        );
};
  
  export default EmbedDialog;