import React from 'react';

const Sources = ({
  source,
  descriptionUrls
}) => {
  return (
    <React.Fragment>
			Source: {source} (
          { descriptionUrls.split('\n').map( (u, index, arr) => {
            return (
              <React.Fragment key={index+1}>
                <a href={u} rel="nofollow">{index+1}</a>{ index < (arr.length - 1) ? ', ' : '' }
              </React.Fragment>
            );
          })})
    </React.Fragment>
  );
};

export default Sources;
