import React from 'react';
import { Tree, Icon } from 'antd';

const TreeNode = Tree.TreeNode;

const TaxonomyTree = (props) => {

	const [state, setState] = React.useState({ expandedKeys: [], selectedKeys: [] });

	const { root } = props;

	const onExpand = (expandedKeys, info) => {
		setState({ expandedKeys });
	}

	const onSelect = (selectedKeys, info) => {
		const sk = selectedKeys[0];

		const child = root.key != sk;
		if (child) {
			// NOTE !R is't better to keep all the navigation in single place, but we don't have it yet
			props.history.push(`/challenges/${sk}`);
		}
		else {
			if (-1 == state.expandedKeys.indexOf(sk)) {
				setState({ expandedKeys: [...state.expandedKeys, sk], selectedKeys: [] });
			} else {
				setState({ expandedKeys: [...state.expandedKeys.filter(k => k != sk)], selectedKeys: [] });
			}
		}
	}

	const getIconType = (node) => {
		switch(node.key) {
			case 'english-grammar': return 'read';
			case 'difficulty-level': return 'dashboard';
			case 'cefr-level': return 'book';
			case 'other': return 'gift';
			default: 
				if (node.children && node.children.length)	return 'tags';
				else return 'tag';
		}
	}

	

	const renderSubTree = (r) => {
		return (
		<TreeNode title={r.title} key={r.key} icon={<Icon type={getIconType(r)} />}>
				{r.children.map(c => renderSubTree(c))}
			</TreeNode>
		);
	}

	return (
		<Tree 
			key={root.key}
			onSelect={onSelect}onExpand={onExpand}
			expandedKeys={state.expandedKeys}
			selectedKeys={state.selectedKeys}
			showIcon
		>
			{renderSubTree(root, true)}
		</Tree>
	);
}


const TaxonomyBrowser = props => props.taxonomy.trees.map(root => (
	<TaxonomyTree
		root={root}
		key={root.key}
		history={props.history}
	/>
));

export default TaxonomyBrowser;

