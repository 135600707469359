import React, { Component } from 'react';
import { getLoginManager } from '../utils/loginManager';
import { isServer } from '../utils/ssrUtils';
import { requireUserAllowAnonymous } from './RequireUser';
import { requireRegisteredUser } from './RequireRegisteredUser';

const ensureUser = (ComposedComponent) => (props) => {
	if (isServer()) return false;

	const loginManager = getLoginManager();

	const userData = loginManager.readLocalUserData();
	const email = userData && (userData.email || userData.name);

	// email -> deny anonymous
	const EnsureUser = email 
		? requireRegisteredUser(ComposedComponent)
		: requireUserAllowAnonymous(ComposedComponent);

	return <EnsureUser {...props} />
};

export { ensureUser };
