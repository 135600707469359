import React from 'react';
import styles from './style.less';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import AuthorName from './AuthorName';

export default ({ author, createdAt, createdBy, modifiedAt, showAuthor = true, showDate = true }) => {
	const userName = author || createdBy;
	const dateUnparsedString = modifiedAt || createdAt;
	const date = new Date(Date.parse(dateUnparsedString));
	return (
	<div>
		{ showAuthor &&
			<div className={styles.author}>
				Author: <AuthorName userName={userName} />
			</div>
		}
		{ showDate &&
			<div className={styles.date}>
				Edited: <Tooltip title={date.toLocaleString()}>{date.toLocaleDateString()}</Tooltip>
			</div>
		}
		<div style={{clear:'both'}} />
	</div>
	);
};

