/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import PageTitle from '../components/PageTitle';
import AnswerComponent from '../components/Answer';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { Answer } from '@kindrobots/web.fe.ui';

import { ANSWER_FIELDS } from '../constants/queries';

const GET_ANSWER = gql`query ($challengeSlug: String!, $sessionUuid: ID) {
  answersByUserChallengeSlugs(challengeSlug: $challengeSlug, sessionUuid: $sessionUuid)
  {
    ${ANSWER_FIELDS}
    challengeSlug
    challengeTitle
    questionSlug
    nextQuestionSlug
    prevQuestionSlug
    questionsTotalNumber
    currentQuestionNumber
    answersMap
  }
}`;

// TODO !D !!! figure out where to take previous and next answer from
// TODO !D !!! figure out how challenge knows if it's the last =>
//      get the data form server?
// TODO !!! figure out if we should use Component or PureComponent
class AnswerContainer extends React.Component {
  toRandomAnswer(challengeSlug, sessionUuid, questionSlug) {
    this.props.history.push(`/answers/${sessionUuid}/${challengeSlug}/${questionSlug}`);
  }

  toNextAnswer(challengeSlug, sessionUuid, nextQuestionSlug) {
    if (!nextQuestionSlug) {
      this.toReport();
    }
    this.toRandomAnswer(challengeSlug, sessionUuid, nextQuestionSlug);
  }

  toPrevAnswer(challengeSlug, sessionUuid, previousQuestionSlug) {
    if (!previousQuestionSlug) {
      // do nothing, this is the 1st question
      return;
    }
    this.toRandomAnswer(challengeSlug, sessionUuid, previousQuestionSlug);
  }

  toReport(challengeSlug, sessionUuid) {
    this.props.history.push(`/report/${sessionUuid}/${challengeSlug}`);
  }

  onExit() {
    this.props.history.push('/challenges');
  }

  render() {
    const { staticContext } = this.props;
    const { params } = this.props.match;
    return (
      <Query
        query={GET_ANSWER}
        variables={{
          sessionUuid: params.session,
          challengeSlug: params.challengeSlug
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error)
            return (<Exception desc={error.message} staticContext={staticContext} />);
          if (null == data.answersByUserChallengeSlugs)
            return (<Exception type={404} staticContext={staticContext} />);
          
          const currentIndex = params.questionSlug ? data.answersByUserChallengeSlugs.findIndex(q => q.questionSlug == params.questionSlug) : 0;
          const answerData = new Answer(data.answersByUserChallengeSlugs[currentIndex]);

          if (staticContext) {
            staticContext.pageTitle = `${answerData.challengeTitle} - question ${answerData.currentQuestionNumber}`;
            staticContext.keywords = answerData.question.tags.map(t => t.title);
          }

          return (
            <>
              <AnswerComponent
                answer={answerData}
                taxonomy={this.props.taxonomy}
                toAnswer={this.toRandomAnswer.bind(this, answerData.challengeSlug, answerData.sessionUuid)}
                toNextAnswer={
                  this.toNextAnswer.bind(this, answerData.challengeSlug, answerData.sessionUuid, answerData.nextQuestionSlug)}
                toPrevAnswer={
                  this.toPrevAnswer.bind(this, answerData.challengeSlug, answerData.sessionUuid, answerData.prevQuestionSlug)}
                toReport={this.toReport.bind(this, answerData.challengeSlug, answerData.sessionUuid)}
                onExit={this.onExit.bind(this)}
              />
              <PageTitle pageTitle={`${answerData.challengeTitle} - question ${answerData.currentQuestionNumber}`} />
            </>
          );
        }}
      </Query>
    );
  }
}

export default AnswerContainer;
