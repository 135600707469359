import React from 'react';
import { Modal, Icon, Button } from 'antd';
import { isServer } from '../../utils/ssrUtils';

const ProfileDeletionConfirmationDialog = ({ onOK, onCancel, visible }) => {
  if (isServer()) return <span />;

  /*
theme="twoTone"
            twoToneColor="#52c41a"
  */

  return (
    <Modal
      title={(
        <>
          <Icon
            type="delete"
            style={{ verticalAlign: 'top', fontSize: '20px' }}
          />
          {' '}
          Profile Deletion
        </>
      )}
      theme="twoTone"
      twoToneColor="#c41a1a"
      onOk={onOK}
      onCancel={onCancel}
      visible={visible}
      footer={(
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="danger" onClick={onOK}>Delete</Button>
        </>
      )}
    >
      <p>
        Are you sure that you want to
        {' '}
        <strong>delete your profile and all the data?</strong>
      </p>
    </Modal>
  );
};

export default ProfileDeletionConfirmationDialog;
