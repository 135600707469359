import React from 'react';
import { Card } from 'antd';

import TagMarkdown from '../TagMarkdown';
import LicenseDisplay from '../LicenseDisplay';

import styles from './style.less';

const TaxonomyTagsCardComponent = ({
  tags,
  taxonomy
}) => {
  return (
    <React.Fragment>
      {tags ? taxonomy.cefrDifficultyLast(tags).map((object, i) => {
        return (
        <Card key={object.uuid} className={styles.taxonomyTagsCard}>
					<TagMarkdown 
						className={styles.taxonomyTagContent}
						source={object.shortDescription || object.description}
					/>
          {object.license && object.descriptionUrls ? (
            <LicenseDisplay license={object.license} source="wikipedia" descriptionUrls={object.descriptionUrls} />
          ) : ''}
        </Card>
        );
      }) : ''}
    </React.Fragment>
  );
};

export default TaxonomyTagsCardComponent;
