import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import styles from './style.less';

const LeaderboardHigh = (props) => (
	<div className={styles.leaderBoardHigh}>
		<div className={styles.bannerInnerContainer}>
			<div className={styles.logoContainer}>
				<img src="/static/media/witch_logo_small.0dd83662.svg" className={styles.logo} />
			</div>
			<div className={styles.textContainer}>
				<span className={styles.text}>This challenge is by the </span>
				<span className={styles.grammarError}><span className={styles.grammar}>Grammar</span> <span className={styles.error}>Error</span></span>
				<span className={styles.text}> team. Your challenge could contain your banner,  <Link to="/article/about">learn more...</Link></span>
			</div>
		</div>
	</div>
);

export default LeaderboardHigh;