import React from 'react';
import { TreeSelect } from 'antd';
import { isServer } from '../../utils/ssrUtils';

import styles from './style.less';

const TreeSelectorsComponent = ({ trees, filter, getDefaultValue, onTaxonomySelectorChange }) => {
  const maxwidth = trees.reduce( (maxwidth, tree) => tree.selectWidth > maxwidth ? tree.selectWidth : maxwidth , 0);
  const innerWidth = !isServer() ? window.innerWidth : 1024;
  const bLimitSize = maxwidth > innerWidth;
  return (
  	<div className={styles.treeSelectors}>
	    { trees.map(tree => (
		    <TreeSelect
		      className={styles.treeSelector}
		      key={tree.key}
		      defaultValue={getDefaultValue(tree)}
		      value={filter[tree.key]}
		      style={{ width: bLimitSize ? innerWidth - 20 : tree.selectWidth }}
		      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
		      treeData={tree.children}
		      placeholder={tree.title}
		      treeCheckable
		      treeNodeFilterProp='title'
		      showCheckedStrategy={TreeSelect.SHOW_PARENT}
		      onChange={(value) => { onTaxonomySelectorChange(tree.value, value); }}
		    />
		    )
		)}
    </div>
  );
};

export default TreeSelectorsComponent;
