import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Menu, Drawer, Icon} from 'antd';

import config from '../../utils/config';

import styles from './style.less';

const forumUrl = config.forumUrl;

const MainMenu = ({isMobile}) => {
    const menu = <Menu theme="dark"
		mode={isMobile ? 'vertical' : 'horizontal' }
		className={styles.menu}
		onClick={isMobile ? () => setVisible(false) : null }
	>
    <Menu.Item key="cefr-test" className={styles.menuItem}>
			<Link className={styles.menuItem} to={`/test-your-english-language-cefr-level`} >Test Your English!</Link>
		</Menu.Item>
		<Menu.Item key="challenges" className={styles.menuItem}>
			<Link className={styles.menuItem} to={`/challenges`} >Challenges</Link>
		</Menu.Item>
		<Menu.Item key="all" className={styles.menuItem}>
			<Link className={styles.menuItem} to={`/article/all`} >Articles</Link>
		</Menu.Item>
		<Menu.Item key="about" className={styles.menuItem}>
			<Link className={styles.menuItem} to={`/article/about`} >About</Link>
		</Menu.Item>
		<Menu.Item key="forum" className={styles.menuItem}>
			<a className={styles.menuItem} href={forumUrl}>Forum</a>
		</Menu.Item>
	</Menu>;

	const [isVisible, setVisible] = useState(false);

	return isMobile ? (
		<>
			<Icon type="menu" className={styles.hamburgerIcon} theme="outlined" onClick={() => setVisible(true)}/>
			<Drawer
				  className={styles.drawer}
		          placement="left"
		          closable={false}
		          onClose={() => setVisible(false)}
		          visible={isVisible}
		          width={200}
		        >
		          {menu}
		    </Drawer>
	    </>
	) : (
		menu
	);
}

export default MainMenu;