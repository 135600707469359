import React, {lazy, Suspense} from 'react';
import { Card, Breadcrumb, Button, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import ReactMarkdown, { renderers } from 'react-markdown/with-html';

import { isServer } from '../../utils/ssrUtils';
const Mindmap = lazy(() => import('../Mindmap'));

import LicenseDisplay from '../LicenseDisplay';
import SharePanel from '../SharePanel';

import styles from './index.less';

const DynamicMindmap = (props) => isServer() 
	? <Skeleton />
	: <Suspense fallback={<Skeleton />}>
		<Mindmap {...props} />
	</Suspense>;


const ArticlePageComponent = ({
	articleData,
}) => {

	const breadcrumbItems = [(
		<Breadcrumb.Item key="home">
			<Link to="/">Home</Link>
		</Breadcrumb.Item>
	)];

	let count = 0;

	const renderers = {
		paragraph: 'div', /* this is needed to avoid warnings like arning: validateDOMNesting(...): <ul> cannot appear as a descendant of <p>. */
		image: props => {
			if (props.src && props.src.startsWith("mindmap://")) 	return (
				<DynamicMindmap {...props} />
			);

			return <img {...props} />;
		},
		parsedHtml: props => {
			if(props.value && props.value.startsWith('<details>')) {
				const detailsValue = props.element.props.children[1];
				// we need to parse markdown here explicitly as react-markdown does not do this by default for whatever reason
				props.element.props.children[1] = (
					<ReactMarkdown key={detailsValue.slice(0,8)}
						source={detailsValue} 
					/>);
			}
			return props.element;
		},
		heading:  props => {
			const headingText = props.children.reduce((sum, next) => 
			{
				const textChunk = sum + ((next.props && next.props.value) || "");
				//console.log(textChunk);
				return textChunk;
			}, "");
			const idSlug = slugify(headingText);
			const newProps = {
				...props,
				level: undefined,
				id: idSlug
			};

			return (props.children && props.children[0]
				? React.createElement(`h${props.level}`, newProps, ...props.children)
				: React.createElement(`h${props.level}`, newProps));
		}
	};

	return (
		<Card title={<>
				<Breadcrumb>{breadcrumbItems}</Breadcrumb>
				<div className={styles.sharePanelTop}><SharePanel  /></div>
			</>} 
			bordered={false}
		>
			<ReactMarkdown source={articleData.markdown} className={`${styles.desc} ${styles.markdownParagraph} forumContent`}
				escapeHtml={false}
				renderers={renderers}
				/>
			Feel free to share this article keeping a link to this page.
			<div className={styles.sharePanel}><SharePanel  /></div>
			<LicenseDisplay license="CC BY-SA 3.0" />
		</Card>
	);
	
};

/* This is taken from markdown-all-in-on VSCode extension and converted to JS from TS */

/**
 * For example: `_italic_` -> `italic`
 * This function is usually used before `slugify`
 * 
 * (Escape syntax like `1.`)
 * 1. md.render(text)
 * 2. textInHtml(text)
 * (Unescape)
 * @param text
 */
function extractText(text) {
	text = text.replace(/\[([^\]]*)\]\[[^\]]*\]/, (_, g1) => g1);
	text = text.replace(/^([\d]+)(\.)/, (_, g1) => g1 + '%dot%');
	text = text.replace(/^([\d]+)(\))/, (_, g1) => g1 + '%par%');

	if (mdEngine.md === undefined) {
			return text;
	}

	const html = mdEngine.md.render(text).replace(/\r?\n$/, '');
	text = textInHtml(html);

	//// Unescape
	text = text.replace('%dot%', '.');
	text = text.replace('%par%', ')');
	return text;
}

function textInHtml(html) {
	//// HTML entities
	let text = html.replace(/(&emsp;)/g, _ => ' ')
			.replace(/(&quot;)/g, _ => '"')
			.replace(/(&lt;)/g, _ => '<')
			.replace(/(&gt;)/g, _ => '>')
			.replace(/(&amp;)/g, _ => '&');
	//// remove <!-- HTML comments -->
	text = text.replace(/(<!--[^>]*?-->)/g, '');
	//// remove HTML tags
	while (/<(span|em|strong|a|p|code)[^>]*>(.*?)<\/\1>/.test(text)) {
			text = text.replace(/<(span|em|strong|a|p|code)[^>]*>(.*?)<\/\1>/g, (_, _g1, g2) => g2)
	}
	text = text.replace(/ +/g, ' ');
	return text;
}

function slugify(heading) {
	let slug = encodeURI(
			heading.trim()
					.toLowerCase()
					.replace(/\s+/g, '-') // Replace whitespace with -
					.replace(/[\]\[\!\'\#\$\%\&\'\(\)\*\+\,\.\/\:\;\<\=\>\?\@\\\^\_\{\|\}\~\`。，、；：？！…—·ˉ¨‘’“”々～‖∶＂＇｀｜〃〔〕〈〉《》「」『』．〖〗【】（）［］｛｝]/g, '') // Remove known punctuators
					.replace(/^\-+/, '') // Remove leading -
					.replace(/\-+$/, '') // Remove trailing -
	);

	return slug;
}

/*  */

export default ArticlePageComponent;
