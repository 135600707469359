// import Oidc from 'oidc-client';
import { window } from './ssrUtils';
import config from './config';

let userManager = null;

// UserManager should be created on client only
const getUserManager = () => {
	const userManagerConfig = {
		client_id: 'grammarerror.com.js',
		redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${config.redirectUriPath}`,
		response_type: 'token id_token',
		scope: 'openid profile email api1',
		authority: config.authorityUrl,
		silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${config.silentRedirectUriPath}`,
		silentRequestTimeout: 10000, // default is 10000 ms
		automaticSilentRenew: false,
		accessTokenExpiringNotificationTime: 60,
		// monitorSerssion default is true
		monitorSession: false,
		// checkSessionInterval: 2000, // (default)
		filterProtocolClaims: true,
		loadUserInfo: false,
		//loadUserInfo: true,
		post_logout_redirect_uri: new URL(config.postLogoutRedirectPath, config.webUrl).toString(),
		// отзывает access_token в соответствии со стандартом https://tools.ietf.org/html/rfc7009
    // revokeAccessTokenOnSignout: true,
		// допустимая погрешность часов на клиенте и серверах, нужна для валидации токенов, по умолчанию 300
    // https://github.com/IdentityModel/oidc-client-js/blob/1.3.0/src/JoseUtil.js#L95
    clockSkew: 300,
		
		// userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
	};

	// we rely on having Oidc bundle being loaded first where Oidc is a global variable
	return userManager || (userManager = new Oidc.UserManager(userManagerConfig));
}

export { getUserManager };
