import React from 'react';
import { Button } from 'antd';

import styles from './style.less';

const QuestionNavComponent = ({
  answersMap,
  currentSlug,
  toAnswer
}) => {

  //const onClick = (questionSlug) => 

  return (
    <div>
      {answersMap
        ? answersMap.map((mapItem, i) => (
          <Button
            key={mapItem.questionSlug}
            className={mapItem.correct
              ? `${styles.success} ${mapItem.questionSlug == currentSlug ? styles.current : ''}`
              : `${styles.error} ${mapItem.questionSlug == currentSlug ? styles.current : ''}`}
            type="primary"
            shape="circle"
            size={mapItem.questionSlug == currentSlug ? 'large' : 'small'}
            onClick={() => toAnswer(mapItem.questionSlug)}
          >
            {mapItem.questionOrder}
          </Button>))
        : ''
      }
    </div>
  );
};

export default QuestionNavComponent;