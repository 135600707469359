import React from 'react';
import { Button } from 'antd';
import { isServer } from '../../utils/ssrUtils';

import styles from './style.less';

const SharePanelComponent = ({ url }) => {
  if (isServer()) {
  	//return different DOM so react update it during client rendering and use window variable.
  	return <div className={styles.sharePanel}></div>;
  } else {
    return (
      <div className={styles.sharePanel}>
        <Button className={styles.shareButton} target="_blank" href={`https://www.facebook.com/sharer.php?u=${url || window.location.href.replace('/embed/', '/')}`} icon="facebook" shape="circle" size="large" />
        <Button className={styles.shareButton} target="_blank" href={`https://twitter.com/share?url=${url || window.location.href.replace('/embed/', '/')}`} icon="twitter" shape="circle" size="large" />
      </div>
    );
  }
};

export default SharePanelComponent;