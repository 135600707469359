import React, { Component } from 'react';
import { Form, Card, Select, List, Button } from 'antd';
import ReactMarkdown from 'react-markdown';

import * as ChallengeStatus from '../../constants/challengeStatus';

import { getCurrentQuestionSlug } from '../../utils/localState';

import TaxonomyTags from '../TaxonomyTags';

import AuthorName from '../MetaInfo/AuthorName';

import styles from './style.less';

const { Option } = Select;
const FormItem = Form.Item;


// NOTE This is a dumb component, btw it's a pattern to make these
//     function components w/o own state & methods
const SearchList = ({
	challenges,
	taxonomy,
	startChallenge,
	continueChallenge,
	showChallengeResults,
	loadMore,
	showLoadMore,
}) => {
	const ListContent = ({ data: { content, href } }) => (
		<div className={styles.listContent}>
			<div className={styles.description}>{content}</div>
		</div>
	);

	const loadMoreControl = showLoadMore ? (
		<div className={styles.loadMore}>
			<Button onClick={() => loadMore()} className={styles.loadMoreButton}>
				Load more
			</Button>
		</div>
	) : null;

	return (
		<Card
			className={styles.listCard}
			bordered={false}
		>
			<List
				className={styles.listContents}
				size="large"
				rowKey="challengeUuid"
				itemLayout="vertical"
				loadMore={loadMoreControl}
				dataSource={challenges}
				renderItem={item => (
					<List.Item
						key={item.challengeUuid}
					>
						{/* FIXME = Use router-generated link in the href */}
						<List.Item.Meta
							title={(
								<>
									<span className={styles.listItemMetaTitle}>{item.title}</span>
									<span className={styles.listItemAuthorName}>&nbsp;by <AuthorName userName={item.author || item.createdBy} /></span>
									<span className={styles.challengeListHeaderSplitter}></span>
								</>
							)}
							description={
								<div>
									<div>
										<ReactMarkdown source={item.shortDescription || item.description} />
									</div>
									<TaxonomyTags tags={item.tags} taxonomy={taxonomy} />
									<div>
										{ item.draft ? (
											<Button size='large' icon='play-circle' className={styles.challengeButton}
												onClick={() =>
													startChallenge(item.slug)}
											>
												View Intro
											</Button>) : '' }
										{ item.status == ChallengeStatus.NOT_STARTED && null == getCurrentQuestionSlug(item.slug) ? (
											<Button size='large' icon='play-circle' className={styles.challengeButton}
												onClick={() =>
													startChallenge(item.slug)}
											>
												Start
											</Button>) : '' }
										{ item.status == ChallengeStatus.STARTED || 
											(getCurrentQuestionSlug(item.slug) && item.status != ChallengeStatus.FAILED && item.status != ChallengeStatus.PASSED) 
											? (
												<Button size='large' icon='forward' className={styles.challengeButton}
													onClick={() =>
														continueChallenge(item.slug)
													}
												>
													Continue
												</Button>) : '' }
										{ item.status == ChallengeStatus.PASSED || item.status == ChallengeStatus.FAILED ? (
											<Button size='large' icon='menu-unfold' className={styles.challengeButton}
												onClick={() =>
													showChallengeResults(item.slug, item.sessionUuid)}
											>
												Results
											</Button>) : '' }
									</div>
								</div>
							}
						/>
						<ListContent data={item} />
					</List.Item>
				)}
			/>
		</Card>
	);
};

export default SearchList;

