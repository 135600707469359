import React, { createElement } from 'react';
import classNames from 'classnames';
import { Button, Card } from 'antd';
import config from './typeConfig';
import styles from './style.less';

export default ({ className, linkElement = 'a', type, title, desc, img, actions, staticContext, ...rest }) => {
  const pageType = type in config ? type : '500';
  if (staticContext) staticContext.statusCode = type;
  const clsString = classNames(styles.exception, className);

  //Hide 500 errors from client side
  //Note DEPLOY_ENV is undefined in hot mode
  let errorText = desc;
  if (errorText && pageType == '500' && process.env.DEPLOY_ENV == 'Production')
    errorText = null;

  return (
    <Card bordered={false}>
      <div className={clsString} {...rest}>
        <div className={styles.imgBlock}>
          <h1>{title || config[pageType].title}</h1>
          <span
            className={styles.imgEle}
            style={{ backgroundImage: `url(${img || config[pageType].img})` }}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.desc}>{errorText || config[pageType].desc}</div>
          <div className={styles.actions}>
            {
              actions ||
                createElement(linkElement, {
                  to: '/',
                  href: '/',
                }, <Button type="primary">Go to home page</Button>)
            }
          </div>
        </div>
      </div>
    </Card>
  );
};
