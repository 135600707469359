/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import PageTitle from '../components/PageTitle';
import TagDetailsComponent from '../components/TagDetails';
import EmbeddedComments from '../components/EmbeddedComments';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

const GET_TAG = gql`
query ($slug: String!) {
  tagBySlug(slug: $slug) {
    uuid
    slug
    title
    description
    descriptionUrls
    license
  }
}`;

class TagContainer extends React.Component {
  render() {
    const { staticContext } = this.props;
    const { params } = this.props.match;

    return (
      <Query
        query={GET_TAG}
        variables={{
          slug: params.tagSlug
        }}
      >
        {({ loading, error, data, client }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error)
            return (<Exception desc={error.message} staticContext={staticContext} />);
          if (null == data.tagBySlug)
            return (<Exception type={404} staticContext={staticContext} />);

          const tagData = data.tagBySlug;

          if (staticContext) {
            staticContext.pageTitle = tagData.title;
            staticContext.keywords = [tagData.title];
          }

          return (
            <>
              <TagDetailsComponent
                data={tagData}
                taxonomy={this.props.taxonomy}
              />
              <EmbeddedComments />
              <PageTitle pageTitle={tagData.title} />
            </>
          );
        }}
      </Query>
    );
  }
}

export default TagContainer;
