import React, { Component } from 'react';
import { getLoginManager } from '../utils/loginManager';
import { isServer } from '../utils/ssrUtils';
import { requireAuth } from './RequireAuth';
import { buildReturnPath } from '../components/Nav';

const requireUserAllowAnonymous = (ComposedComponent) => (props) => {
	if (isServer()) return false;

	const loginManager = getLoginManager();
	const RequireUser = requireAuth(
		ComposedComponent, 
		() => loginManager.ensureUserAllowAnonymous({returnUrl: buildReturnPath(window)})
	);
	return <RequireUser {...props} />;
};
export { requireUserAllowAnonymous };
