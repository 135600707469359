import React from 'react';
import { Table } from 'antd';
import ReactMarkdown from 'react-markdown';

import classNames from 'classnames';

import styles from './style.less';

const getTableColumns = props => {
	if (!props.children[0] || !props.children[0].props.children[0]) return null;

	const headerCells = props.children[0].props.children[0].props.children;

	return headerCells.map((cell, index) => {
		const textElem = cell.props.children[0];
		return (<Table.Column
			title={textElem} /* (textElem && textElem.props.value)  || "" */
			dataIndex={index}
			key={cell.key}
			render={elem => elem} /* it's already a react elem */
		/>);
	});
}

const getTableRows = props => {
	if (!props.children[1] || !props.children[1].props.children[0]) return null;

	const rows = props.children[1].props.children;
	return rows.map((row, rowIndex) => {
		
		const cells = row.props.children;

		const antdCellsValues = cells.map((cell, cellIndex) => {		
			return cell.props.children;
		});

		const antdRowValue = Object.assign({}, antdCellsValues);
		antdRowValue.key = row.key;

		return antdRowValue;
	});
}

const mdTableRenderer = props => {
	const columns = getTableColumns(props);
	const rows = getTableRows(props);

	return <Table dataSource={rows} pagination={false} className={styles.markdownTable}>
		{columns}
	</Table>;
};

const mdLinkRenderer = props => <a href={props.href} target="_blank">{props.children}</a>

const TagMarkdown = props => {
	
	const clsString = classNames(props.className, styles.tagMarkdown, styles.desc);
	
	return (<ReactMarkdown 
		{...props}
		className={clsString}
		renderers={{
			...props.renderers,
			link: mdLinkRenderer,
			table: mdTableRenderer
		}}
	/>)
};

export default TagMarkdown;
