import uuid from 'uuid';
import { getUserManager } from './userManager';
import { getClientLogger } from './clientLogger';
import { isServer, localStorage } from './ssrUtils';

// FIXME !!! ensure email stored after registration


// FIXME !!! delete excessive API chunks

// FIXME !!! save user email & data to localStorage after user registration

// FIXME !!! pass user id along during registration/external login

// LoginManager should be created on client only
class LoginManager {
	constructor() {
		this.userManager = getUserManager();

		getClientLogger();
		Log.info("userManager", this.userManager);

		this.userManager.events.addUserLoaded(this.onUserLoaded);
		this.userManager.events.addSilentRenewError(this.onSilentRenewError);
		this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
		this.userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
		this.userManager.events.addUserUnloaded(this.onUserUnloaded);
		this.userManager.events.addUserSignedOut(this.onUserSignedOut);

		// unregister the event callbacks
		// this.userManager.events.removeUserLoaded(this.onUserLoaded);
		// this.userManager.events.removeSilentRenewError(this.onSilentRenewError);
		// this.userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired);
		// this.userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring);
		// this.userManager.events.removeUserUnloaded(this.onUserUnloaded);
		// this.userManager.events.removeUserSignedOut(this.onUserSignedOut);

		// setState idiom is used to emphasize the symmetry between what is stored here
		//		and what is passed to LoginProvider components
		this.state = { isLoadingUser: false, user: undefined, userError: undefined }
		this.loginProviders = [];

		this.loadUser();
	}

	addProvider = (provider) => {
		this.loginProviders.push(provider);
	}

	updateProviders = () => {
		this.loginProviders.forEach(p => p.update());
	};

	setState = (newState) => {
		// merge states to have all the fields
		Log.info("loginManager.setState, value:", newState);

		const update = (null != newState.isLoadingUser) && (this.state.isLoadingUser !== newState.isLoadingUser)
			|| (newState.user || newState.user === null) || (newState.userError || newState.userError === null);

		if(!update) {
			Log.info("loginManager.setState; no changes to state, providers are not updated");
			return;
		}

		Object.assign(this.state, newState);
		Log.info("loginManager.setState, new state is:", this.state);
		this.updateProviders();
	}

	// this should be called somewhere at client-side application start
  loadUser = async () => {
		Log.debug("loadUser: started");
		
		if (!this.userManager || !this.userManager.getUser) {
			throw new Error('LoginManager.loadUser: You need to pass oidc-client UserManager as a property!');
		}

		// this might be set on the server
		if(!this.state.isLoadingUser) {
			this.setState({isLoadingUser: true});
		}

		try {
			// NOTE for some reason userLoaded event is not raised here 
			// check if oidc user is stored locally
			Log.debug(`LoginManager.loadUser: UserManager.getUser called`)
			let user = await this.userManager.getUser();

			Log.debug(`LoginManager.loadUser: UserManager.getUser success callback, user=${JSON.stringify(user)}`);
			if (user) {
				Log.debug(`LoginManager.loadUser: user exists, setting user`, user)
				this.setState({isLoadingUser: false, user});
			} else {
				// oidc user is not stored locally -> get currently logged in user or log in as anonymous
				if(!this.isUserRegistered()) {
					Log.debug(`LoginManager.loadUser: getUser return null, and user is not registered, performing silent sign in`);
					return await this.signinIframeAllowAnonymous();
				} else {
					Log.debug(`LoginManager.loadUser: getUser return null, but user is registered, performing silent sign in`);
					// we don't know if cookie exists until we do this
					// FIXME !!! we should not set the error in .setState here for login_required
					return await this.signInIframeDontAllowAnonymous();
				}
			}

			// oidc-client needed to do housekeeping of its state stored in local state storage (e.g. sessionState)
			// https://github.com/IdentityModel/oidc-client-js/issues/429
			// NOTE commented out for now - causing bugs
			// await this.userManager.clearStaleState();
		} catch(error) {
			// don't start a drama if we are just denied silent login and are required to actually log in
			if(this.isLoginRequiredError(error)) {
				// no op
			}

			Log.debug(`UserManager.getUser: error callback, error=${JSON.stringify(error)}`);
			this.setState({isLoadingUser: false, userError: error});
			// throw(error); 
		}
	}

	ensureUserAllowAnonymous = ({returnUrl}) => {
		if(!this.state.user) {
			this.signInRedirect({ state: { returnUrl } });
		}
	}

	ensureRegisteredUser = ({returnUrl}) => {
		if(!this.state.user || this.isUserAnonymous(this.state.user)) {
			this.signInRedirectDontAllowAnonymous({ state: { returnUrl } });
		}
	}

	signInRedirect = async (args) => {
		this.setState({isLoadingUser: true});
		this.userManager.signinRedirect({
			...args
		});
	}

	signInRedirectDontAllowAnonymous = async (args) => {
		this.setState({isLoadingUser: true});
		this.userManager.signinRedirect({
			acr_values: 'allow_anonymous:false',
			...args
		});
	}

	signinIframeAllowAnonymous = async (args) => {
		Log.info("LoginManager.signinIframeAllowAnonymous");
		if(!this.state.isLoadingUser) {
			this.setState({isLoadingUser: true});
		}
		try {
			const user = await this.retry(
				{times: 5, interval: 11000}, 
				async () => {
					return await this.userManager.signinSilent({
						acr_values: 'allow_anonymous:true',
						prompt: 'none',
						...args
					});
				},error => {
					Log.info(`LoginManager.loadUser retry attempt failed, error:`, error);
				}
			);
			Log.info("LoginManager.signinIframeAllowAnonymous: user:", user);
			this.setState({ isLoadingUser: false, user});
		} catch (error) {
			Log.error("LoginManager.signInErrorCallback: error", error);
			this.setState({ isLoadingUser: false, userError: error});
		}
	}

	signInIframeDontAllowAnonymous = async (args) => {
		Log.info("LoginManager.signInIframeDontAllowAnonymous");
		if(!this.state.isLoadingUser) {
			this.setState({isLoadingUser: true});
		}
		try {
			const user = await this.retry(
				{times: 5, interval: 11000}, 
				async () => {
					return await this.userManager.signinSilent({
						acr_values: 'allow_anonymous:false',
						prompt: 'none',
						...args
					});
				}, 
				error => {
					if(this.isLoginRequiredError(error)) throw error;
					Log.info(`LoginManager.loadUser retry attempt failed, error:`, error);
				}
			);
			Log.info("LoginManager.signInIframeDontAllowAnonymous: user:", user);
			// this.setState({ isLoadingUser: false, user});
		} catch (error) {
			if(!this.isLoginRequiredError(error)) {
				this.setState({ isLoadingUser: false, userError: error});
				Log.error("LoginManager.signInIframeDontAllowAnonymous: error", error);
			} else {
				Log.info("LoginManager.signInIframeDontAllowAnonymous: login_required");
			}
		}
	}

	signInSilent = async (args) => {
		// this is based on original code for silent renew + implements retry 
		try {
			const user = await this.retry(
				{times: 5, interval: 11000}, 
				async () => { 
					return await this.userManager.signinSilent();
				}, 
				error => {
					Log.info(`LoginManager.signInSilent retry attempt failed, error:`, error);
					if(this.isLoginRequiredError(error)) throw new Error("LoginManager.retry Stop retrying condition is true");
				}
			);
			Log.debug("LoginManager.signInSilent: Silent token renewal successful");
		} catch (err) {
			Log.error("LoginManager.signInSilent: Error from signinSilent:", err.message);
			throw(err);
		}
	}


	signInCallback = (user) => {
		Log.info("LoginManager.signInCallback: user:", user);
		this.saveLocalUserData(user);
		this.setState({ isLoadingUser: false, user});
	}

	signInErrorCallback = (error) => {
		Log.error("LoginManager.signInErrorCallback: error", error);
		this.setState({ isLoadingUser: false, userError: error});
	}

	signOutRedirect = (args) => this.userManager.signoutRedirect(args);

	isUserAnonymous = (user) => {
		return user && user.profile && user.profile.idp === "anonymous" && user.profile.acr == 0;
	}

	isUserLoading = () => this.state.isLoadingUser;

	// NOTE I hope that onAccessTokenExpired gets called and assigns user null
	isUserAuthenticated = () => !!this.state.user;

// this also return true in case when user has Auth cookie, but haven't received access token yet
	// currently the function is used token renew, where initial user load/signinSilent is expected to be finished
	// let it be simple for now to avoid complicating things
	hasUserLoggedOut = () => {
		return !this.state.user && this.isUserRegistered();
	}

	isUserRegistered = () => {
		return !!this.readLocalUserData();
	}

	getAuthenticatedUserId = () => {
		Log.info('getAuthenticatedUserId', this.state.user && this.state.user.profile.sub);
		return this.state.user && this.state.user.profile.sub;
	}

	getUserAccessToken = () => {
		return this.state.user && this.state.user.access_token;
	};


	// NOTE it's prohibited to create UserManager directly now, but some APIs still expect it
	//		so we use the getter for now, later we may decide to rewrite the APIs
	getUserManager = () => {
		return this.userManager;
	}

	// event callback when the user has been loaded (on silent renew or redirect)
	onUserLoaded = (user) => {
		Log.info("onUserLoaded", user);
		this.setState({ isLoadingUser: false, user, userError: null });
	};

	onUserLoadedError = (error) => {
		Log.info("onUserLoadedError", error);
		this.setState({ isLoadingUser: false, user: null, userError: error });
	};

	// event callback when silent renew errored
	onSilentRenewError = (error) => {
		//this.setState({ isLoadingUser: false, user: null, userError: error });
	};

	// event callback when the access token expired
	onAccessTokenExpired = () => {
		// TODO figure out if we need to show user login in LoginInfo panel when the user does not have active access token
		this.setState({ isLoadingUser: false, user: null, userError: null });
	};

	// event callback when the user is logged out
	onUserUnloaded = () => {
		// #391 Users: logout is resulted in user being logged in back to the same page bug
		// this.setState({ isLoadingUser: false, user: null, userError: null });
	};
	
		// event callback when the user is signed out
	onUserSignedOut = () => {
		this.setState({ isLoadingUser: false, user: null, userError: null });
	}

	// event callback when the user is expiring
	onAccessTokenExpiring = async () => {
		if(this.hasUserLoggedOut()) {
			Log.debug("LoginManager.onAccessTokenExpiring: a user has logged out, he will need to log in to renew access token");
			// TODO figure out if we need to show user login in LoginInfo panel when the user does not have active access token
			return;
		}

		// this is based on original code for silent renew + implements retry 
		try {
			const user = this.signInSilent();
			Log.debug("LoginManager.onAccessTokenExpiring: Silent token renewal successful");
		} catch (err) {
			Log.error("LoginManager.onAccessTokenExpiring: Error from signinSilent:", err.message);
			this.userManager.events._raiseSilentRenewError(err);
		}
	}

	retry = async ({times, interval}, action, onFailure) => {
		try{
			Log.info(`LoginManager.retry attempt 1 or ${times}, interval is ${interval}`);
			return await action();
		} catch (err) {
			onFailure(err);

			for(let i = 1; i < times; ++i) {
				try {
					return await new Promise(async (resolve, reject) => {
						setTimeout(async () => {
							Log.info(`LoginManager.retry attempt ${i+1} or ${times}, interval is ${interval}`);
							try {
								const result = await action();
								resolve(result);
								Log.info(`LoginManager.retry attempt succeded, result:`, result);
							} catch (ae) {
								reject(ae);
							}
						}, interval);
					});
				} catch (e) {
					onFailure(e);
				}
			}
			throw new Error("LoginManager.retry Could not perform the action");
		}
	}

	isLoginRequiredError = err => {
		return err.error == "login_required";
	};

	isAccessDeniedError = err => {
		return err.error == "access_denied";
	};

	readLocalUserData = () => {
		const userDataJson = localStorage.getItem('localUserData');
		Log.debug(`LoginManager.readLocalUserData: ${userDataJson}`);
		return userDataJson && JSON.parse(userDataJson);
	}

	saveLocalUserData = (user) => {
		if(!user || !user.profile || this.isUserAnonymous(user)) return;

		const userData = { email: user.profile.email, name: user.profile.name }
		const userDataJson = JSON.stringify(userData);
		Log.debug(`LoginManager.saveLocalUserData: ${userDataJson}`);
		localStorage.setItem('localUserData', userDataJson);
	}
}

let loginManager = null;

const getLoginManager = () => {
	return loginManager || (loginManager = new LoginManager());
}

export { getLoginManager };