import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isServer } from '../utils/ssrUtils';
import { getLoginManager } from '../utils/loginManager';
import { getClientLogger } from '../utils/clientLogger';

// the job of this container is to stay in touch with LoginManager
//		and to cause its children to update with new login-related values
// TODO now withLoginProvider also causes updates
//		do we need LoginProvider now at all?
//			-it causes user refresh when needed
class LoginProvider extends Component {

	constructor(props) {
		super(props);
		// are we on server?
		if (!isServer()) {
			// adds window.Log 
			getClientLogger();
			this.loginManager = getLoginManager();
			this.loginManager.addProvider(this);
		}
	}

	update = () => {
		this.setState({ dummy: "force update" });
	}

	render() {
		return (
			<React.Fragment>
				{ this.props.children }
			</React.Fragment>
		);
	}

	componentDidMount = () => {
   	// force re-render with the login manager
		if (this.props.location.search && this.props.location.search.substr('refreshLogin')) {
			Log.info(`LoginProvider: renewing login status after registration...`);
			try {
				/* const u = await*/ getLoginManager().signInIframeDontAllowAnonymous();
				Log.info("LoginProvider: user logged in after registration.");
			} catch (e) {
				
				Log.info(`LoginProvider: error occured when logging in after registration: ${e}`);
			};
		}
	}
}


// This HOC does is not intended to cause React subtree update but
//		 to pass current loginManager state to the wrapped component along.
//		Think react-router 'withRouter' or react-redux 'connect'
function withLoginProvider(C) {
	class WithLoginProvider extends Component {
		constructor(props) {
			super(props);
			if (!isServer()) {
				getClientLogger();
				this.loginManager = getLoginManager();
				this.loginManager.addProvider(this);
				Log.debug('withLoginProvider.constructor');
			}
		}
	
		update = () => {
			this.setState({ dummy: "force update" });
		}

		render() {
			const propsToMerge = isServer()
				? { isLoadingUser: true }
				: { ...this.props, ...getLoginManager().state };

			if(!isServer()) Log.debug('withLoginProvider.render', propsToMerge);

			return <C {...propsToMerge} />; // <- is this what causes the remount?
		}

		componentDidMount() {
			Log.debug('withLoginProvider.componentDidMount');
		}

		componentDidUpdate() {
			Log.debug('withLoginProvider.componentDidUpdate');
		}
	}

	WithLoginProvider.displayName = "withLoginProvider(" + (C.displayName || C.name) + ")";

	return WithLoginProvider;
}

export { withLoginProvider };
export default withRouter(LoginProvider);
