import React from 'react';
import { Route, Switch, Router } from 'react-router'; // react-router v4
import PropTypes from 'prop-types';

import HomePage from './components/homePage';
import Root from './components/root';
import ScrollToTop from './components/ScrollToTop';
import ErrorMessage from './components/errorMessage';

import ChallengeList from './containers/ChallengeList';
import Intro from './containers/Intro';
import Question from './containers/Question';
import Answer from './containers/Answer';
import CorrectAnswer from './containers/CorrectAnswer';
import Report from './containers/Report';
import PublicProfile from './containers/PublicProfile';
import Profile from './containers/Profile';
import Settings from './containers/Settings';
import Tag from './containers/Tag';
import ArticlePage from './containers/ArticlePage';
import LegalPage from './containers/LegalPage';
import TestIntro from './containers/TestIntro';
import TestQuestion from './containers/TestQuestion';
import TestReport from './containers/TestReport';

import Exception from './components/Exception';

import CallbackPage from './components/CallbackPage';


class Routes extends React.Component {
	render() {
		const props = this.props;
		const taxonomy = props.taxonomy;
		return (
			<Switch>
				<Route path="/embed/intro/:challengeSlug" render={(props) => <Intro embedded taxonomy={taxonomy} {...props} />} />
				<Route path="/embed/challenge/:challengeSlug/:questionSlug?" render={(props) => <Question embedded taxonomy={taxonomy} {...props} />} />
				<Route path="/embed/report/:session/:challengeSlug" render={(props) => <Report embedded {...props} />} />
				<Route>
					<ScrollToTop>
						<Root>
							<div>
								<Switch>
									<Route path="/" exact render={(props) => <HomePage {...props} taxonomy={taxonomy} />} />
									<Route path="/profile" component={Profile} />
									<Route path="/settings" component={Settings} />
									<Route path="/challenges/:tagSlug?" render={(props) => <ChallengeList {...props} taxonomy={taxonomy} />} />
									<Route path="/intro/:challengeSlug" render={(props) => <Intro {...props} taxonomy={taxonomy} />} />
									<Route path="/challenge/:challengeSlug/:questionSlug?" render={(props) => <Question {...props} taxonomy={taxonomy} />} />

									<Route path="/test-your-english-language-cefr-level/questions" render={(props) => <TestQuestion {...props} taxonomy={taxonomy} />} />
                  <Route path="/test-your-english-language-cefr-level/report/:reportUuid" component={(props) => <TestReport {...props} taxonomy={taxonomy} />} />
									<Route path="/test-your-english-language-cefr-level" render={(props) => <TestIntro {...props} taxonomy={taxonomy} />} />

                  
                  <Route path="/tags/:tagSlug" render={(props) => <Tag {...props} taxonomy={taxonomy} />} />
									<Route path="/answers/:session/:challengeSlug/:questionSlug?" render={(props) => <Answer {...props} taxonomy={taxonomy} />} />
									<Route path="/correctanswers/:challengeSlug/:questionSlug?" render={(props) => <CorrectAnswer {...props} taxonomy={taxonomy} />} />
									<Route path="/report/:session/:challengeSlug" component={Report} />
									<Route path="/viewprofile/:userName" component={PublicProfile} />
									<Route path="/legal/:documentSlug" render={(props) => <LegalPage {...props} />} />
									<Route path="/article/:articleSlug" render={(props) => <ArticlePage {...props} />} />
									{/* Mockup page - <Route path="/mockup" component={Mockup} /> */}
									{/* Auth */}
									<Route path="/auth-callback.html" render={(props) => <CallbackPage {...props} />} />

									{/* silent renew auth-callback-silent.html is a static file now, we should never go this far with the url
										<Route path="/auth-callback-silent.html" component={SilentRenewPage} /> */}
									{/* Error handling */}
									<Route render={(props) => {
										return <Exception type={404} staticContext={props.staticContext} />;
									}
									}
									/>
								</Switch>
							</div>
						</Root>
					</ScrollToTop>
					<ErrorMessage />
				</Route>
			</Switch>
		);
	}
}

/* eslint-disable react/forbid-prop-types */
Routes.propTypes = {
	taxonomy: PropTypes.object.isRequired,
};
/* eslint-enable react/forbid-prop-types */

export default Routes;
