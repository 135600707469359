import React from 'react';
import { Button, Breadcrumb, Card, Progress, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { QuestionDisplay } from '@kindrobots/web.fe.ui';

import TaxonomyTags from '../TaxonomyTags';
import TaxonomyTagsCard from '../TaxonomyTagsCard';
import QuestionNav from '../QuestionNav';
import EmbeddedComments from '../EmbeddedComments';

import MetaInfo from '../MetaInfo';

import styles from './style.less';

const CorrectAnswer = ({
  question,
  taxonomy,
  onExit,
  toAnswer,
  toNextAnswer,
  toPrevAnswer,
  toIntro
}) => {
  const first = !question.prevQuestionSlug;
  const last = !question.nextQuestionSlug;

  const childprops = {
    question,
    answer: question.getCorrectAnswer(),
    disabled: true,
  };

  const percentage = Math.round(100*(question.currentQuestionNumber)/question.questionsTotalNumber);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="home">
      <Link to="/challenges">Challenges</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key={question.challengeSlug}>
      <Link to={`/intro/${question.challengeSlug}`}>{question.challengeTitle.trim()}</Link>
    </Breadcrumb.Item>
  ];

  return (
    <React.Fragment>
      <Card
        title={
          <Breadcrumb>
            {breadcrumbItems}
          </Breadcrumb>
        }
        bordered={false}
        className={styles.card}
      >
        <div className={`${styles.questionBox} forumContent`}>
          <Progress percent={percentage} strokeWidth={16} />
          <QuestionDisplay {...childprops} />
        </div>
        <TaxonomyTags tags={question && question.tags ? question.tags : []} taxonomy={taxonomy} />
        <Button size='large' className={styles.controlButton} onClick={onExit} >To Challenges</Button>
        <Button size='large' className={styles.controlButton} onClick={toIntro} type="primary">Start Challenge</Button>
        {
          first || <Button size='large' className={styles.controlButton} onClick={toPrevAnswer}>Previous</Button>
        }
        {
          last || <Button size='large' className={styles.controlButton} onClick={toNextAnswer}>Next</Button>
        }
        <MetaInfo {...question} />
      </Card>
      <EmbeddedComments path={`/intro/${question.challengeSlug}`} />
      <TaxonomyTagsCard tags={question && question.tags ? question.tags : []} taxonomy={taxonomy} />
    </React.Fragment>
  );
};

export default CorrectAnswer;
