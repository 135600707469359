import React from 'react';
import { Row, Col, Button, Card, Tag } from 'antd';
import ReactDOM from 'react-dom';

import ReactMarkdown from 'react-markdown';

import TaxonomyTags from '../TaxonomyTags';

import MetaInfo from '../MetaInfo';

import styles from './style.less';

let lastHeight = -1;

class EmbeddedIntroComponent extends React.Component {
  constructor(props) {
    super(props);
    this.frameRef = React.createRef();
  }

  componentDidMount() {
    this.updateFrameHeight();
  }

  componentDidUpdate() {
    this.updateFrameHeight();
  }

  updateFrameHeight() {
    if (this.frameRef.current && lastHeight != this.frameRef.current.scrollHeight && window.parent) {
      window.parent.postMessage({ id: "gmsh", h: this.frameRef.current.scrollHeight }, '*');
      lastHeight = this.frameRef.current.scrollHeight;
    }
  }

  render() {
    const { challengeData, taxonomy, startChallenge, embedded=false, showTitle=true, showDate=false, actions=null } = this.props;
    const buttons = actions || (
      <div>
        <Button className={styles.controlButton} size='large' type="primary" onClick={startChallenge}>
          Start Challenge
        </Button>
      </div>
    );

    const content = <>
      { showTitle && <h2>{challengeData.title}</h2> }
      <TaxonomyTags tags={challengeData.tags} taxonomy={taxonomy} />
      {challengeData.shortDescription && buttons}
      <ReactMarkdown source={challengeData.description} />
      {buttons}
      <MetaInfo {...challengeData} showDate={showDate} />
    </>;

    return embedded ? (
        <div ref={this.frameRef} className={styles.frame}>
          { content }
        </div>
      ) : content;
  }
}

export default EmbeddedIntroComponent;
