import React from 'react';
import { Row, Col, Button, Icon, Progress, Popover } from 'antd';
import SharePanel from '../SharePanel';

import styles from './style.less';

const CompletedChallenges = ({
  data,
  toAnswers,
  canShare
}) => {

  const titleMap = {
    error: 'Challenge failed',
    success: 'Challenge passed',
  };
	const challenges = data.length > 0
		? data.map(item => {
				const resultType = item.passed ? "success" : "error";
				return (
					<div className={styles.challengeBox} key={item.challengeSlug}>
						<div className={styles.titleBox}>
							<span className={styles.challengeTitle} onClick={() => toAnswers(item.challengeSlug, item.challengeAnsweringSessionUuid)}>{item.challengeTitle}</span>
						</div>
						{ item.passed ?
							(
								<Progress percent={Math.round(100*item.nCorrect/item.nQuestions)} format={percent => percent + '%'} strokeWidth={16} />
							)
							:
							(
								<Progress percent={Math.round(100*item.nCorrect/item.nQuestions)} format={percent => percent + '%'} status="exception" strokeColor={'red'} strokeWidth={16} />
							)
						}
						<div className={styles.titleBox}>
							<span>{titleMap[resultType]} at&nbsp;
								{new Intl.DateTimeFormat('en-US', {
										year: 'numeric',
										month: 'long',
										day: '2-digit',
								}).format(Date.parse(item.sessionFinished))}
							</span>
						</div>
						<div className={styles.achievementsBox}>
							{ item.achievements && item.achievements.map(achieve => {
									return (
											<span key={achieve.name} className={styles.achievementItem}>
												<Popover content={achieve.comment} trigger="hover">
													<Icon type={achieve.type} theme="outlined" />{achieve.name}
												</Popover>
											</span>
										);
								})}
						</div>
					</div>
				);
			})
		: "No challenges completed yet";


  return (
    <div className={styles.reportCard}>
      <div className={styles.reportBox}>
			<h1><Icon type='trophy' /> Completed challenges</h1>
			{canShare && <SharePanel/>}
			{challenges}
      </div>
    </div>
  );
};

export default CompletedChallenges;
