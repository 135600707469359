import React, { Component } from 'react';

import { TreeSelect } from 'antd';

import { isError401 } from '../utils/common';
import { getLoginManager } from '../utils/loginManager';

import ChallengeListComponent from '../components/ChallengeList';
import Exception from '../components/Exception';
import LoadingIndicator from '../components/LoadingIndicator';
import ChallengeListQuerySelector from './ChallengeListQuerySelector';
import TreeSelectors from '../components/TreeSelectors';
import LoginProposal from '../components/LoginProposal';

import * as ChallengeStatus from '../constants/challengeStatus';

import config from '../utils/config';

import { ensureUser } from './EnsureUser';

class ChallengeList extends Component {
  constructor(props) {
    super(props);
    this.startChallenge = this.startChallenge.bind(this);
    this.continueChallenge = this.continueChallenge.bind(this);
    this.showChallengeResults = this.showChallengeResults.bind(this);
    this.onTaxonomySelectorChange = this.onTaxonomySelectorChange.bind(this);
    this.getDefaultTreeSelectorValue = this.getDefaultTreeSelectorValue.bind(this);
    this.state = {
      showMore: true,
      filterInited: false,
      filter: {},
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    if (!params.tagSlug || !this.props.taxonomy.trees.length)
      return;
    const rootSlug = this.props.taxonomy.findRootKeyByChildKey(params.tagSlug);
    if (!rootSlug) {
      this.setState({
        filterInited: true
      });
      return;
    }
    const filter = {
      [rootSlug]: [params.tagSlug]
    }
    this.setState({
      filter,
      filterInited: true
    });
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props.match;
    if (!params.tagSlug || this.state.filterInited || !this.props.taxonomy.trees.length)
      return;
    const rootSlug = this.props.taxonomy.findRootKeyByChildKey(params.tagSlug);
    if (!rootSlug) {
      this.setState({
        filterInited: true
      });
      return;
    }
    const filter = {
      [rootSlug]: [params.tagSlug]
    }
    this.setState({
      filter,
      filterInited: true
    });
  }

  startChallenge(challengeSlug) {
    this.props.history.push(`/intro/${challengeSlug}`);
  }

  continueChallenge(challengeSlug) {
    this.props.history.push(`/challenge/${challengeSlug}`);
  }

  showChallengeResults(challengeSlug, sessionUuid) {
		const { history } = this.props;
    this.props.history.push(`/report/${sessionUuid}/${challengeSlug}`);
  }

  onTaxonomySelectorChange(treevalue, value) {
    this.setState({
      filter: {
        ...this.state.filter,
        [treevalue] : value
      }
    });
  }

  getDefaultTreeSelectorValue(tree) {
    const { params } = this.props.match;
    return this.props.taxonomy.belongsToTree(tree, params.tagSlug) ? [params.tagSlug] : []
  }

  filterChallenges(filter, challenges) {
    const filters = Object.values(filter);
    return challenges.filter(challenge => {
      return filters.length == 0 || filters.every(orsArr => {
        return orsArr.length == 0 || this.props.taxonomy.expandTags(orsArr).some(tagslug => {
          return challenge.tags.some(tag => tag.slug == tagslug);
        })
      });
    });
  }

  render() {
    const { params } = this.props.match;
    const limit = 50;
    return (
      <ChallengeListQuerySelector limit={limit} >
        {({ loading, error, data, fetchMore }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );

          if (error) {
            // FIXME this is a quick fix, taking into account our plans to ditch GraphQL altogether
            const is401 = isError401(error);
            return (<Exception desc={is401 ? 'Unathorized' : error.message} type={is401 ? 401 : undefined} staticContext={this.props.staticContext} />);
          }

          //initial condition
          const showLoadMore = this.state.showMore && (data.challenges.length >= limit);

          // TODO how to pass progress here?
          // @connect(({ list, progress }) => ({
          return (
            <React.Fragment>
              <LoginProposal user={this.props.user} enabled={data.challenges.some(c => c.status != ChallengeStatus.NOT_STARTED)} />
              <TreeSelectors
                trees={this.props.taxonomy.trees}
                filter={this.state.filter}
                onTaxonomySelectorChange={this.onTaxonomySelectorChange}
                getDefaultValue={this.getDefaultTreeSelectorValue}
              />
              <ChallengeListComponent
                challenges={this.filterChallenges(this.state.filter, data.challenges)}
                taxonomy={this.props.taxonomy}
                startChallenge={this.startChallenge}
                continueChallenge={this.continueChallenge}
                showChallengeResults={this.showChallengeResults}
                loadMore={() =>
                  fetchMore({
                    variables: {
                      offset: data.challenges.length
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult || !fetchMoreResult.challenges || !fetchMoreResult.challenges.length) {
                        this.setState({showMore: false});
                        return prev;
                      }
                      if (fetchMoreResult.challenges.length < limit)
                        this.setState({showMore: false});
                      return Object.assign({}, prev, {
                        challenges: [...prev.challenges, ...fetchMoreResult.challenges]
                      });
                    }
                  })
                }
                showLoadMore={showLoadMore}
              />
            </React.Fragment>
          );
        }}
      </ChallengeListQuerySelector>
    );
  }
}

export default ensureUser(ChallengeList);
