/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { getLoginManager } from '../utils/loginManager';
import { isServer } from '../utils/ssrUtils';
import {
  getCEFRStats, getTagsTriage, getAnswersTagStats, calculateCefrLevel
} from '../utils/cefrTestUtils';
import { GET_ALL_TAGS } from '../constants/queries';
import CEFRReportComponent from '../components/CEFRReport';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';


export const TEST_REPORT_FIELDS = `
  uuid
  challengeUuid
  userId
  json
  createdAt
`;

const GET_TEST_REPORT_BY_UUID = gql`query ($reportUuid: ID!) {
  testReportByUuid(reportUuid: $reportUuid) {
    ${TEST_REPORT_FIELDS}
  }
}`;

function hasUser() {
  if (isServer()) return false;

  return getLoginManager().isUserRegistered();
}

class TestReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasUser: hasUser(),
    };
  }

  onExit() {
    this.props.history.push('/');
  }

  toProfile() {
    this.props.history.push('/profile');
  }

  render() {
    const { params } = this.props.match;
    return (
      <Query
        query={GET_TEST_REPORT_BY_UUID}
        variables={{
          reportUuid: params.reportUuid,
        }}
      >
        {({ loading, error, data }) => (
          <Query query={GET_ALL_TAGS}>
            {({ loading: tags_loading, data: tags_data, error: tags_error }) => {
              if (loading || tags_loading) {
                return (
                  <aside>
                    <LoadingIndicator />
                  </aside>
                );
              }
              if (error || tags_error) {
                return (
                  <Exception
                    desc={error.message}
                    staticContext={this.props.staticContext}
                  />
                );
              }

              const results = data.testReportByUuid;
              if (results == null) {
                return (
                  <Exception type={404} staticContext={this.props.staticContext} />
                );
              }
              const answers = JSON.parse(data.testReportByUuid.json);
              const stats = getAnswersTagStats(answers);
              const cefrStats = getCEFRStats(stats);
              const triage = getTagsTriage(stats, [
                'cefr-level', 'cefr-a1', 'cefr-a2', 'cefr-b1', 'cefr-b2', 'cefr-c1', 'cefr-c2',
                'difficulty-level', 'difficulty-easy', 'difficulty-medium', 'difficulty-hard'
              ]);
              const a1a2Ratio = cefrStats.a1a2.correct / cefrStats.a1a2.total;
              const b1b2Ratio = cefrStats.b1b2.correct / cefrStats.b1b2.total;
              const c1c2Ratio = cefrStats.c1c2.correct / cefrStats.c1c2.total;
              const totalRatio = (cefrStats.a1a2.correct + cefrStats.b1b2.correct + cefrStats.c1c2.correct)
                / (cefrStats.a1a2.total + cefrStats.b1b2.total + cefrStats.c1c2.total);

              const testReport = Object.assign({
                a1a2Ratio,
                b1b2Ratio,
                c1c2Ratio,
                totalRatio,
                level: calculateCefrLevel(a1a2Ratio, b1b2Ratio, c1c2Ratio)
              }, stats, cefrStats, triage);

              const allTags = tags_data.tags;
              return (
                <CEFRReportComponent
                  taxonomy={this.props.taxonomy}
                  history={this.props.history}
                  report={testReport}
                  allTags={allTags}
                  onExit={this.onExit.bind(this)}
                  toProfile={this.state.hasUser ? this.toProfile.bind(this) : null}
                />
              );
            }}
          </Query>
        )}
      </Query>
    );
  }
}

export default TestReport;
