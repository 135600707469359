import React from 'react';
import ReactDOM from 'react-dom';
import { Icon, Button } from 'antd';

import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';

import styles from './style.less';

class ProfileDescriptionEditor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tab: 'write',
			descriptionDraft: props.description
		};
	}

	descriptionDraftChange = (markdown) => {
		this.setState({ descriptionDraft: markdown });
	};

	handleTabChange = tab => {
		this.setState({ tab });
	};

	render() {
		const {
			description,
			cancelDescriptionChange,
			completeDescriptionChange
		} = this.props;

		const latestDescription = this.state.descriptionDraft == undefined
			? description
			: this.state.descriptionDraft;

		return (
			<>
				<ReactMde
					onChange={this.descriptionDraftChange}
					generateMarkdownPreview={markdown => {
						return new Promise(resolve => {
							let a = document.createElement('div');
							ReactDOM.render(<ReactMarkdown source={markdown} />, a, () => {
								resolve(a.innerHTML);
							});
						});
					}}
					value={latestDescription}
					onTabChange={this.handleTabChange}
					selectedTab={this.state.tab}
				/>
				<div className={styles.subEditBordered}>
					<span className={styles.buttonWrap}>
						<Button onClick={() => completeDescriptionChange(latestDescription)}>
							<Icon type='check' /> Save
						</Button>
					</span>
					<span>
						<Button onClick={cancelDescriptionChange}>
							<Icon type='close' /> Cancel
						</Button>
					</span>
				</div>
			</>
		);
	}
}

export default ProfileDescriptionEditor;
