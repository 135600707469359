import React, { Component } from 'react';
import { enquireScreen } from 'enquire-js';

import config from '../../utils/config';
import { isServer } from '../../utils/ssrUtils';

import styles from './style.less';

const UserBanner = ({challengeBanners}) => {
	//for SSR enquireScreen thinks it is desktop, so, turn it off in SSR
	if (isServer()) return (<div></div>); //fake div to trick correct style going from SSR to live

	let isMobile;
	enquireScreen((b) => {
	  isMobile = b;
	});

	let type = isMobile ? 'mobile300x250' : 'desktop728x90';
	let banners = challengeBanners.filter(banner => banner.type == type);
	let banner = banners[0] || challengeBanners[0];
	return (
		<div>
			<div className={styles[type]}>
				<a href={banner.bannerTarget}><img src={`${config.apiUrl}${banner.bannerPath}`} /></a>
			</div>
		</div>
	);
}

export default UserBanner;