import React, { Component } from 'react';

import { Modal, Icon } from 'antd';

import { getLoginManager } from '../../utils/loginManager';
import { isServer } from '../../utils/ssrUtils';

import {buildReturnPath} from '../Nav';

class LoginProposal extends Component {

	onOK = () => {
		getLoginManager().signInRedirectDontAllowAnonymous({ state: { returnUrl: this.props.returnURL || buildReturnPath(window) } });
	};

	onCancel = () => {
		sessionStorage.setItem('LoginProposalDismissed', true);
		//if parent handler is set, parent is responsible for hiding component
		if (this.props.onLoginProposalCancel)
			this.props.onLoginProposalCancel();
		else
			this.forceUpdate();
	};

	isVisible = () => {
		return getLoginManager().isUserAnonymous(this.props.user) && !sessionStorage.getItem('LoginProposalDismissed');
	}

	render() {
		if (isServer())
			return <span></span>;

		const visible = this.props.enabled && this.isVisible();
		return (
			<Modal
					title={<><Icon type="save" style={{verticalAlign: "top", fontSize: "20px"}} theme="twoTone" twoToneColor="#52c41a" /> Save Your Progress</>}
					visible={visible}
					onOk={this.onOK}
					onCancel={this.onCancel}
					okText="Yes!"
					cancelText="Later"
			>
				<p>Want to save your progress or access it from other devices?</p>

				<p>Sign in using a social network or sign up with us!</p>
			</Modal>
		);
	}
}

export default LoginProposal;
