import React, { Component } from 'react';
import { getLoginManager } from '../utils/loginManager';
import { requireAuth } from './RequireAuth';
import  { withLoginProvider } from './LoginProvider';

import { buildReturnPath } from '../components/Nav';

const requireRegisteredUser = (ComposedComponent) => requireAuth(
	ComposedComponent, 
	() => getLoginManager().ensureRegisteredUser({returnUrl: buildReturnPath(window) })
);

// const requireRegisteredUser = (ComposedComponent) => withLoginProvider(ComposedComponent);

export { requireRegisteredUser };
