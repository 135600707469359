/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import PageTitle from '../components/PageTitle';
import CorrectAnswerComponent from '../components/CorrectAnswer';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { Question } from '@kindrobots/web.fe.ui';

import { QUESTION_FIELDS } from '../constants/queries';

const GET_QUESTION = gql`
query ($challengeSlug: String!, $questionSlug: String) {
  questionBySlug(challengeSlug: $challengeSlug, questionSlug: $questionSlug) {
    ${QUESTION_FIELDS}
    challengeSlug
    challengeTitle
    prevQuestionSlug
    nextQuestionSlug
    questionsTotalNumber
    currentQuestionNumber
  }
}`;

// const { match: { params }, challenge: { current, failed_fetch }, challenges } = this.props;
class CorrectAnswerContainer extends React.Component {

  constructor(props) {
    super(props);
    this.onExit = this.onExit.bind(this);
  }

  onExit() {
    this.props.history.push('/challenges');
  }

  toIntro(challengeSlug) {
    this.props.history.push(`/intro/${challengeSlug}`);
  }

  toAnswer(challengeSlug, questionSlug) {
    this.props.history.push(`/correctanswers/${challengeSlug}/${questionSlug}`);
  }

  toNextAnswer(challengeSlug, nextQuestionSlug) {
    if (!nextQuestionSlug) {
      this.toReport();
    }
    this.toAnswer(challengeSlug, nextQuestionSlug);
  }

  toPrevAnswer(challengeSlug, previousQuestionSlug) {
    if (!previousQuestionSlug) {
      // do nothing, this is the 1st question
      return;
    }
    this.toAnswer(challengeSlug, previousQuestionSlug);
  }

  render() {
    const { staticContext } = this.props;
    const { params } = this.props.match;

    return (
      <Query
        query={GET_QUESTION}
        variables={{
          challengeSlug: params.challengeSlug,
          questionSlug: params.questionSlug
        }}
      >
        {({ loading, error, data, client }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error) {
            // NOTE this is related to #224
            // TODO analyse errors, mb it's 404, mb it's 401, mb it's 500 etc
            //  we now add statusCode to GraphQL errors
            //  also research onError  https://www.apollographql.com/docs/react/features/error-handling.html

            // const getStatusCode = e => e && e.extensions && e.extensions.data && e.extensions.data.statusCode;

            // if(error.graphQLErrors) {
            //   if (error.graphQLErrors.find(e => getStatusCode(e) == 500)) return (<Exception type={500} />);
            //   if (error.graphQLErrors.find(e => getStatusCode(e) == 404)) return (<Exception type={404} />);
            // }

            return (<Exception desc={error.message} staticContext={staticContext} />);
          }

          // NOTE this is related to #224
          // no network or graphql errors e.g. 404 and still no data ~ something wrong with Apollo?
          if (!data.questionBySlug) return <Exception type={404} staticContext={staticContext} />;

          const questionData = new Question(data.questionBySlug);

          if (staticContext) {
            staticContext.pageTitle = `${questionData.challengeTitle} - question ${questionData.currentQuestionNumber}`;
            staticContext.keywords = questionData.tags.map(t => t.title);
          }

          return (
            <>
              <CorrectAnswerComponent
                question={questionData}
                taxonomy={this.props.taxonomy}
                onExit={this.onExit}
                toIntro={this.toIntro.bind(this, questionData.challengeSlug)}
                toAnswer={this.toAnswer.bind(this, questionData.challengeSlug)}
                toNextAnswer={this.toNextAnswer.bind(this, questionData.challengeSlug, questionData.nextQuestionSlug)}
                toPrevAnswer={this.toPrevAnswer.bind(this, questionData.challengeSlug, questionData.prevQuestionSlug)}
              />
              <PageTitle pageTitle={`${questionData.challengeTitle} - question ${questionData.currentQuestionNumber}`} />
            </>
          );
        }}
      </Query>
    );
  }
}

export default CorrectAnswerContainer;
