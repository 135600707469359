import React, { Component } from 'react';
import { isServer } from '../utils/ssrUtils';

const clientOnly = (ComposedComponent) => {
	class ClientOnly extends Component {
		constructor(props) {
			super(props);
			this.state = { server: isServer() }
		}
	
		render() {
			if (this.state.server) return false;
	
			return <ComposedComponent {...this.props} />;
		}
	}

	return ClientOnly;
}

export { clientOnly };