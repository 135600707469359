/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  Row, Col, Button, Card, Icon, Progress, Tooltip, Tag
} from 'antd';
import { Link } from 'react-router-dom';
import SharePanel from '../SharePanel';
import TaxonomyTagsCard from '../TaxonomyTagsCard';
import styles from './style.less';

const SimpleTagsList = ({
  tags,
  color
}) => (
  <div className={styles.taxonomyTags}>
    {tags.map((object, i) => (
      <Tooltip key={object.uuid} title="Click show challenges for the tag...">
        <Link to={`/tags/${object.slug}`} target="_blank">
          <Tag className={styles.taxonomyTag} color={color}>{object.title}</Tag>
        </Link>
      </Tooltip>
    ))}
  </div>
);


const CEFRReport = (props) => {
  const toHome = () => props.history.push('/');

  const { report, allTags } = props;

  const cefrTagsSlugs = ['cefr-a1', 'cefr-a2', 'cefr-b1', 'cefr-b2', 'cefr-c1', 'cefr-c2'];
  const cefrTags = cefrTagsSlugs.map(ct => allTags.find(t => t.slug === ct));

  const slugTitles = {
    'cefr-a1': 'A1 | Elementary | Beginners',
    'cefr-a2': 'A2 | Elementary | Pre-intermediate',
    'cefr-b1': 'B1 | Intermediate',
    'cefr-b2': 'B2 | Upper Intermediate',
    'cefr-c1': 'C1 | Advanced',
    'cefr-c2': 'C2 | Proficiency'
  };

  const knowsSomething = 0.5;
  const knowsOK = 0.65;
  const knowsWell = 0.8;

  // calculating progress bar color
  const getProgressStatus = (ratio) => {
    if (ratio < knowsSomething) return 'exception';
    if (ratio < knowsWell) return 'normal';

    return 'success';
  };

  const colors = {
    green: '#52c41a',
    blue: '#4096ff',
    red: '#fa541c'
  };

  const actions = (
    <div>
      <Button
        className={styles.standard}
        size="large"
        type="primary"
        onClick={toHome}
      >
        Home
      </Button>
    </div>
  );

  const strongTags = allTags.filter(t => report.strong.some(rt => rt === t.slug));
  const okTags = allTags.filter(t => report.ok.some(rt => rt === t.slug));
  const weakTags = allTags.filter(t => report.weak.some(rt => rt === t.slug));

  return (
    <>
      <Card className={styles.reportCard} bordered={false}>
        <div className={styles.frame}>
          <div className={styles.resultIcon}><Icon key="check" /></div>
          <h1>
Your CEFR Level is
            {' '}
            <strong>{slugTitles[report.level]}</strong>
          </h1>
          <div className={styles.reportBox}>
            <p>
              <strong>{slugTitles[report.level]}</strong>
              &nbsp;is our best estimate of your English Grammar mastery level. Share the results to challenge your friends or&nbsp;
              <Link to="/test-your-english-language-cefr-level" target="_blank">retake the test</Link>
              &nbsp;using a different set of questions.
            </p>
            <SharePanel />
            <h2>Correct Answers</h2>
            <h3><strong>TOTAL</strong></h3>
            <Progress
              percent={Math.round(report.totalRatio * 100)}
              format={percent => `${percent}%`}
              strokeWidth={16}
              status={getProgressStatus(report.totalRatio)}
            />
            <p>&nbsp;</p>
            <p>
Questions can be matched with different
              {' '}
              <Link to="/tags/cefr-level">CEFR levels</Link>
&nbsp;
          using
              {' '}
              <a href="https://www.coe.int/en/web/portfolio/overview-of-cefr-related-scales">EAQUALS descriptors</a>
.
          Here is your performance for different levels.

            </p>
            <h3><strong>A1 & A2 specific questions</strong></h3>
            <Progress
              percent={Math.round(report.a1a2Ratio * 100)}
              format={percent => `${percent}%`}
              strokeWidth={16}
              status={getProgressStatus(report.a1a2Ratio)}
            />
            <br />
            <br />
            <h3><strong>B1 & B2 specific questions</strong></h3>
            <Progress
              percent={Math.round(report.b1b2Ratio * 100)}
              format={percent => `${percent}%`}
              strokeWidth={16}
              status={getProgressStatus(report.b1b2Ratio)}
            />
            <br />
            <br />
            <h3><strong>C1 & C2 specific questions</strong></h3>
            <Progress
              percent={Math.round(report.c1c2Ratio * 100)}
              format={percent => `${percent}%`}
              strokeWidth={16}
              status={getProgressStatus(report.c1c2Ratio)}
            />
            <p>&nbsp;</p>
            <h2>Grammar Topics Knowledge</h2>
            <p>Use this feedback to target the blanks in your English grammar knowledge saving time on topics you already know. </p>
            <h3>Strong</h3>
            <SimpleTagsList tags={strongTags} color={colors.green} />
            <h3>Fair</h3>
            <SimpleTagsList tags={okTags} color={colors.blue} />
            <h3>Weak</h3>
            <SimpleTagsList tags={weakTags} color={colors.red} />

          </div>


          <div className={styles.actions}>{actions}</div>
        </div>
      </Card>
      <TaxonomyTagsCard tags={cefrTags} taxonomy={props.taxonomy} />
    </>
  );
};

export default CEFRReport;
