import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import config from '../../utils/config';
import { getLoginManager }  from '../../utils/loginManager';

import { isServer } from '../../utils/ssrUtils';

const buildReturnPath = (_window) => _window.location.pathname + _window.location.search + _window.location.hash;

const buildReturnUrl = (returnPath, refresh/*, _window*/) => {
  // if(!_window && !isServer()) _window = window;
  const returnPathURL =  new URL(returnPath ? returnPath : '/', window.location.origin); // we use base url just to make new URL a valid call
  if(refresh) {
    if (returnPathURL.searchParams)
      returnPathURL.searchParams.append('refreshLogin', 1);
    else {//for old Chrome < 51 used in Google search console
      returnPathURL.search = !!returnPathURL.search ? returnPathURL.search + '&refreshLogin=1' : '?refreshLogin=1';
    }
  }
  return returnPathURL;
}

const buildRegisterUrl = (returnPath) => {
  const returnUrl = buildReturnUrl(returnPath, true);
  const path = `/Account/Register?returnUrl=${encodeURIComponent(returnUrl)}`;
  return new URL(path, config.authorityUrl);
}

const logOut = returnPath => getLoginManager().signOutRedirect({ state: { returnUrl: returnPath } });

const LoginLink = (props) => {
  const { returnPath } = props;
  const doLogin = () => getLoginManager().signInRedirectDontAllowAnonymous({ state: { returnUrl: returnPath} });
  return <a className={props.className} style={props.style} onClick={doLogin}>{props.children}</a>;
}

const LogoutLink = (props) => {
  const { returnPath } = props;
 
  return <a className={props.className} style={props.style} onClick={() => logOut(returnPath)}>{props.children}</a>;
}

const RegisterLink = (props) => {
  return (
    <a className={props.className}
      style={props.style}
      href={buildRegisterUrl(props.returnPath)}
      rel="nofollow"
    >
      {props.children}
    </a>
  );
}

const RegisterButton = (props) => {
  return (
    <Button 
      className={props.className}
      style={props.style}
      href={buildRegisterUrl(props.returnPath)}
      rel="nofollow"
    >
      {props.children}
    </Button>
  );
}

const ProfileLink = (props) => {
  return <Link className={props.className} style={props.style} to={`/profile?returnPath=${encodeURIComponent(props.returnPath)}`}>{props.children}</Link>;
}

const SettingsLink = (props) => {
  return <Link className={props.className} style={props.style} to={`/settings?returnPath=${encodeURIComponent(props.returnPath)}`} >{props.children}</Link>;
}

const ChangePasswordLink = (props) => {
  const changePasswordUrl = (returnPath) => {
    
    const returnUrl = buildReturnUrl(returnPath, true);
    const path = `/Account/ChangePassword?returnUrl=${encodeURIComponent(returnUrl)}`;
    return new URL(path, config.authorityUrl);
  }
  return <a className={props.className} style={props.style} href={changePasswordUrl(props.returnPath)}>{props.children}</a>;
}

const ChangeUsernameLink = (props) => {
  const changeUsernameUrl = (returnPath) => {
    const returnUrl = buildReturnUrl(returnPath, true);
    const path = `/Account/ChangeUsername?returnUrl=${encodeURIComponent(returnUrl)}`;
    return new URL(path, config.authorityUrl);
  }
  return <a className={props.className} style={props.style} href={changeUsernameUrl(props.returnPath)}>{props.children}</a>;
}

const RenderChildren = (props) => {
  return <>{props.children}</>;
}

class Nav {
  static LoginLink = !isServer() ? LoginLink : RenderChildren;
  static LogoutLink = LogoutLink;
  static RegisterLink = !isServer() ? RegisterLink : RenderChildren;
  static RegisterButton = !isServer() ? RegisterButton : RenderChildren;
  static ProfileLink = ProfileLink;
  static SettingsLink = SettingsLink;
  static ChangePasswordLink = ChangePasswordLink;
  static ChangeUsernameLink = ChangeUsernameLink;
}

export { Nav, buildReturnPath, logOut };
