/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { Query, Mutation } from 'react-apollo';
import { Alert } from 'antd';
import gql from 'graphql-tag';

import { Redirect } from 'react-router-dom';

import { DragDropContextProvider } from 'react-dnd'

import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';

import { isError401, extractSessionId } from '../utils/common';
import { getLoginManager } from '../utils/loginManager';
import { getClientLogger } from '../utils/clientLogger';

import { getLocalAnswers, addLocalAnswer, resetLocalAnswers, getCurrentQuestionSlug, setCurrentQuestionSlug, resetCurrentQuestionSlug } from '../utils/localState';

import PageTitle from '../components/PageTitle';
import QuestionComponent from '../components/Question';
import EmbeddedQuestionComponent from '../components/EmbeddedQuestion';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';

import { Question } from '@kindrobots/web.fe.ui';

import { ensureUser } from './EnsureUser';

import LoginProposal from '../components/LoginProposal';

import config from '../utils/config';

import { GET_INTRO_FOR_CHALLENGE, GET_CHALLENGE_QUESTIONS, GET_PROFILE } from '../constants/queries';
import { isServer } from '../utils/ssrUtils';

// FIXME !!! what to return
const SUBMIT_ANSWERS = gql`
mutation ($challengeSlug: String!, $answers: [AnswerInput!]) {
  submitChallengeAnswers(challengeSlug: $challengeSlug, answers: $answers)
  {
    sessionUuid
    challengeStatus
  }
}`;

// const { match: { params }, challenge: { current, failed_fetch }, challenges } = this.props;
class QuestionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.loginProposalRef = React.createRef();
    this.state = {
      loginProposalLandingURL: null
    }
    getClientLogger();
  }

  onLoginProposalCancel = () => {
    this.props.history.push(this.state.loginProposalLandingURL);
  }

  submitAnswer(
    challengeSlug,
    nextQuestionSlug,
    submitMutationCallback,
    answer
  ) {
    addLocalAnswer(challengeSlug, answer.toAnswerInput());
    const last = !nextQuestionSlug;
    // TODO !!! process answer submission
    if (last) {
      submitMutationCallback({
        variables: {
          challengeSlug,
          answers: getLocalAnswers(challengeSlug)
        }
      });
    } else {
      setCurrentQuestionSlug(challengeSlug, nextQuestionSlug);
      this.props.history.push(`${this.props.embedded ? '/embed' : ''}/challenge/${challengeSlug}/${nextQuestionSlug}`);      
    }
  }

  // aka Apollo "update" function
  submitAnswerCallback(
    challengeSlug,
    cache, { data: { submitChallengeAnswers } }
  ) {
    //fix for issue #65
    //try - if we're directly on the URL, parent query may be still not loaded - I could not find a way to check if it is already in cache
    try {
      //if readQuery fails, it throws
      const { challengeBySlug } = cache.readQuery({
        query: GET_INTRO_FOR_CHALLENGE,
        variables: { challengeSlug },
      });
      cache.writeFragment({
        id: challengeBySlug.uuid,
        fragment: gql`
          fragment status on Challenge {
            challengeUuid
            status
            __typename
          }
        `,
        data: {
          challengeUuid: challengeBySlug.uuid,
          status: submitChallengeAnswers.challengeStatus,
          __typename: 'Challenge',
        },
      });
    } catch(err) {
      //error is expected if challengeBySlug is not loaded
      //this is possible if page was loaded directly - and we do not care, since challenges list is to be loaded anyway
      //or when user bypass intro page - and we again do not care, since challenge already in "started" status
    }

    //fix for issue #327 - update list of taken challenges
    resetCurrentQuestionSlug(challengeSlug);
    resetLocalAnswers(challengeSlug);
    if (this.loginProposalRef.current && this.loginProposalRef.current.isVisible()) {
      this.setState({
        loginProposalLandingURL: `/report/${submitChallengeAnswers.sessionUuid}/${challengeSlug}`
      });
    } else {
      this.props.history.push(`${this.props.embedded ? '/embed' : ''}/report/${submitChallengeAnswers.sessionUuid}/${challengeSlug}`);
    }
  }

  onExit() {
    this.props.history.push('/challenges');
  }

  render() {
    const { staticContext } = this.props;
		const { params } = this.props.match;

    return (
      <DragDropContextProvider backend={MultiBackend(HTML5toTouch)}>
      <Query
        query={GET_CHALLENGE_QUESTIONS}
        variables={{
          challengeSlug: params.challengeSlug
        }}
      >
        {({ loading, error, data, client }) => {
          if (loading)
            return (
              <aside>
                <LoadingIndicator />
              </aside>
            );
          if (error) {
            // NOTE this is related to #224
            // TODO analyse errors, mb it's 404, mb it's 401, mb it's 500 etc
            //  we now add statusCode to GraphQL errors
            //  also research onError  https://www.apollographql.com/docs/react/features/error-handling.html

            // const getStatusCode = e => e && e.extensions && e.extensions.data && e.extensions.data.statusCode;

            // if(error.graphQLErrors) {
            //   if (error.graphQLErrors.find(e => getStatusCode(e) == 500)) return (<Exception type={500} />);
            //   if (error.graphQLErrors.find(e => getStatusCode(e) == 404)) return (<Exception type={404} />);
            // }

            // FIXME this is a quick fix, taking into account our plans to ditch GraphQL altogether
            const sessionid = extractSessionId(error);
            if (sessionid) {
              return <Redirect to={`/report/${sessionid}/${params.challengeSlug}`} />
            }

				    const is401 = isError401(error);
				    return (<Exception desc={is401 ? 'Unathorized' : error.message} type={is401 ? 401 : undefined} staticContext={staticContext} />);
          }

          // NOTE this is related to #224
          // no network or graphql errors e.g. 404 and still no data ~ something wrong with Apollo?
          if (!data.challengeBySlug) return <Exception type={404} staticContext={staticContext} />;

          const currentQuestionSlug = params.questionSlug ? params.questionSlug : getCurrentQuestionSlug(params.challengeSlug);
          const currentIndex = currentQuestionSlug ? data.challengeBySlug.questions.findIndex(q => q.slug == currentQuestionSlug) : 0;

          const questionData = new Question({ ...data.challengeBySlug.questions[currentIndex], 
            challengeSlug: params.challengeSlug,
            challengeTitle: data.challengeBySlug.title,
            nextQuestionSlug: currentIndex < data.challengeBySlug.questions.length - 1 ? data.challengeBySlug.questions[currentIndex+1].slug : null,
            questionsTotalNumber: data.challengeBySlug.questions.length,
            currentQuestionNumber: currentIndex + 1
          });

          const testType = data.challengeBySlug.type;

          if (staticContext) {
            staticContext.pageTitle = `${questionData.challengeTitle} - question ${questionData.currentQuestionNumber}`;
            staticContext.keywords = questionData.tags.map(t => t.title);
          }

          //fix for issue #74
          //set current question to just loaded question
          if (params.questionSlug)
            setCurrentQuestionSlug(params.challengeSlug, params.questionSlug);

          return (
            <Mutation
              mutation={SUBMIT_ANSWERS} /* NOTE ??? Do we need it? key={id} */
              update={this.submitAnswerCallback.bind(
                this,
                questionData.challengeSlug
              )}
              refetchQueries={!questionData.nextQuestionSlug && !getLoginManager().isUserAnonymous(this.props.user) ?
                [{
                  query: GET_PROFILE
                }]
              :
                []
              }
              onError={error => console.log(JSON.stringify(error))} /* //to prevent crush from repeated submission if challenge is answered, but user uses "back" button in browser */
            >
              {(triggerSubmitAnswerMutation, { data, loading, error }) => {
                return (
	              <>
                  { this.props.embedded ? (
                      <EmbeddedQuestionComponent
                        mutating={loading}
                        question={questionData}
                        key={questionData.slug}
                        submitAnswer={this.submitAnswer.bind(
                          this,
                          questionData.challengeSlug,
                          questionData.nextQuestionSlug,
                          triggerSubmitAnswerMutation,
                        )}
                      />
                    ) : (
                      <>
                        <LoginProposal
                          enabled={!!this.state.loginProposalLandingURL}
                          user={this.props.user}
                          returnURL={this.state.loginProposalLandingURL}
                          onLoginProposalCancel={this.onLoginProposalCancel}
                          ref={this.loginProposalRef}
                        />
                        <QuestionComponent
                          mutating={loading}
                          question={questionData}
                          taxonomy={this.props.taxonomy}
                          key={questionData.slug}
                          submitAnswer={this.submitAnswer.bind(
                            this,
                            questionData.challengeSlug,
                            questionData.nextQuestionSlug,
                            triggerSubmitAnswerMutation,
                          )}
                          onExit={this.onExit.bind(this)}
                          /* Nick: quick fix for location not being available during SSR */
                          path={!isServer() && location.pathname}
                          site={config.webUrl}
                          testType={testType}
                        />
                      </>
                    )
                  }
	                { error && <Alert message={`${error.message}`} type="error" />}
                  <PageTitle pageTitle={`${questionData.challengeTitle} - question ${questionData.currentQuestionNumber}`} />
	              </>
              );}}
            </Mutation>
          );
        }}
      </Query>
      </DragDropContextProvider>
    );
  }
}

export default ensureUser(QuestionContainer);
