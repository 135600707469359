/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
import React from 'react';
import { Query, Mutation } from 'react-apollo';

import { message } from 'antd';

import { isError401 } from '../utils/common';
import { getLoginManager } from '../utils/loginManager';
import { getClientLogger } from '../utils/clientLogger';
import SettingsComponent from '../components/Settings';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';
import { logOut } from '../components/Nav';

import { GET_USER_SETTINGS, UPDATE_USER_SETTINGS } from '../constants/queries';
import { requireRegisteredUser } from './RequireRegisteredUser';

class Settings extends React.Component {

  constructor(props) {
    super(props);
    getClientLogger();
    this.hideProgress = null;
    this.resultMessage = null;
  }

  settingsChanged = (triggerMutation, settings) => {
    this.submitSettings(triggerMutation, settings);
  }

  goBack = (props) => {
    const params = new URLSearchParams(window.location.search);
    const returnPath = params.get("returnPath");
    if(returnPath) {
      props.history.push(returnPath);
    } else {
      props.history.goBack();
    }
  }

  areSettingsValid = (settings) => {
    // FIXME !!! validate on the server
    return true;
  }

  submitSettings = (triggerMutation, userSettings) => {
    if (this.areSettingsValid(userSettings)) {
      triggerMutation({
        variables: { userSettings }
      });
    }
  }

  submitSettingsCallback = (cache, response) => {
    const { data, error } = response;
    this.setStatusMessage(data,error);
    if(data.updateUserSettings.deleted) {
      logOut('/');
    }
  }

  setLoadingMessage = (isLoading) => {
    if (isLoading && !this.hideProgress) {
      this.hideProgress = message.loading('Action in progress..', 0);
    }

    if (!isLoading && this.hideProgress) {
      this.hideProgress();
      this.hideProgress = null;
    }
  }

  setStatusMessage = (data, error) => {
    if (error) {
      message.error(error.message, 20);
    } 

    if (data && !error) {
      message.success("Settings are updated successfully", 5);
    }
  }

  setCredentialsChangeResultMessage = (props) => {
    if(props.location) {
      const { search } = props.location;
      if (search) {
        if(-1 != search.indexOf('result=success') && !this.resultMessage) {
          this.resultMessage = message.success("Credential was changed successfully", 20);
        }
        if(-1 != search.indexOf('result=cancel') && !this.resultMessage) {
          this.resultMessage = message.warning("Credential change was canceled", 20);
        }
      }
    }
  }

  render() {
    const {user, isLoadingUser, userError, history, match: { params } } = this.props;
     /* NOTE: we need userId to form link to shareable achievements page */
    return (
      <Query
        query={GET_USER_SETTINGS}
      >
        {({ loading, error, data: data }) => {
          if (loading) return (<aside><LoadingIndicator /></aside>);

          if (error) {
            // FIXME this is a quick fix, taking into account our plans to ditch GraphQL altogether
            const is401 = isError401(error);
            return (<Exception desc={is401 ? 'Unathorized' : error.message} type={is401 ? 401 : undefined} staticContext={this.props.staticContext} />);
          }

          if (!data || !data.userSettings)
            return (<Exception type={404} staticContext={this.props.staticContext} />);

          let userSettings = data.userSettings;

          return (
            <Mutation
              mutation={UPDATE_USER_SETTINGS}
              update={this.submitSettingsCallback}
              >
              {(triggerSubmitSettingsMutation, { data, loading, error } ) => {
                
                if(data && data.updateUserSettings) {
                  userSettings = data.updateUserSettings;
                }
                
                return <>
                  {this.setLoadingMessage(loading)}
                  <SettingsComponent
                    settings={userSettings}
                    goBack={() => this.goBack(this.props)}
                    settingsChanged={this.settingsChanged.bind(this, triggerSubmitSettingsMutation)}
                    isLoading={loading}
                  />
                </>;
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }

  componentDidMount = () => {
    Log.debug('Settings.componentDidMount');
    this.setCredentialsChangeResultMessage(this.props);
  }

  componentDidUpdate = () => {
    Log.debug('Settings.componentDidUpdate');
  }
}

export default requireRegisteredUser(Settings);
