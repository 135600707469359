import gql from 'graphql-tag';

export const QUESTION_FIELDS = `
uuid
slug
type
text
author
createdAt
createdBy
modifiedAt
tags {
  uuid
  slug
  title
  shortDescription
  description
  license
  descriptionUrls
}`;

export const ANSWER_FIELDS = `
uuid
sessionUuid
questionUuid
question {
  ${QUESTION_FIELDS}
}
json
isCorrect`;

export const GET_CHALLENGE_LIST = gql`query ($offset: Int, $limit: Int) {
  challenges: challengesForUser(offset: $offset, limit: $limit) {
    challengeUuid
    title
    slug
    shortDescription
    description
    status
    sessionUuid
    sessionReportUuid
    draft
    tags
    {
      uuid
      slug
      title
      description
    }
    author
    createdBy
    createdAt
    modifiedAt
  }
}`;

export const GET_CHALLENGE_LIST_BY_TAG = gql`query ($tagSlug: String!, $offset: Int, $limit: Int) {
  challenges: challengesForUserByTag(tagSlug: $tagSlug, offset: $offset, limit: $limit) {
    challengeUuid
    title
    slug
    shortDescription
    description
    status
    sessionUuid
    sessionReportUuid
    tags
    {
      uuid
      slug
      title
      description
    }
    author
    createdBy
    createdAt
    modifiedAt
  }
}`;

export const GET_INTRO_FOR_CHALLENGE = gql`query ($challengeSlug: String!) {
  challengeBySlug(slug: $challengeSlug) {
    uuid
    title
    shortDescription
    description
    slug
    tags
    {
      uuid
      slug
      title
      shortDescription
			description
			license
			descriptionUrls
    }
    author
    createdBy
    createdAt
    modifiedAt
    testType
  }
}`;

export const GET_CHALLENGE_QUESTIONS = gql`query ($challengeSlug: String!) {
  challengeBySlug(slug: $challengeSlug) {
    uuid
    title
    questions {
      ${QUESTION_FIELDS}
    }
  }
}`;


export const REPORT_FIELDS = `
    id
    uuid
    challengeUuid
    challengeTitle
    challengeSlug
    challengeAnsweringSessionUuid
    nQuestions
    nCorrect
    passed
    sessionFinished
`;

//will be used for 'Load More' that we have more that 50 challenges?
export const GET_REPORTS = gql`query ($userId: ID, $offset: Int, $limit: Int) {
  reports: challengeAnsweringSessions(userId: $userId, offset: $offset, limit: $limit) {
    ${REPORT_FIELDS}
  }
}`;

//will be used for 'Load More' that we have more that 50 challenges?
export const GET_PUBLIC_PROFILE_WITH_REPORTS = gql`query ($userName: String!) {
  profile: publicUserProfile(userName: $userName) {
    username
    dateRegistered
    image
    profileData {
      description
      showCompletedChallenges
    }
    challengesPassed
    challengesFailed
    results
    {
      ${REPORT_FIELDS}
    }
  }
}`;
// dateRegistered
// dateLastActivity

const USER_PROFILE_FIELDS = `
uuid
username
dateRegistered
image
profileData {
	description
	showCompletedChallenges
}
challengesPassed
challengesFailed
results
{
	${REPORT_FIELDS}
}
`;

export const GET_PROFILE = gql`query {
  profile: userProfile {${USER_PROFILE_FIELDS}}
}`;
// dateRegistered
// dateLastActivity
// email
// isExternal
// externalProviderID
// externalProviderDisplayName


export const UPDATE_USER_PROFILE = gql`mutation ($userProfile: UserProfileInput!) {
  updateUserProfile(userProfile: $userProfile) {${USER_PROFILE_FIELDS}}
}`;

const USER_SETTINGS_FIELDS = `
username
email
isExternal
externalProviderID
notificationSettings {
	changesToPlatform
	newChallenges
	newsForContentAuthors
}
`;

export const GET_USER_SETTINGS = gql`query {
	userSettings {${USER_SETTINGS_FIELDS}}
}`;

export const UPDATE_USER_SETTINGS = gql`mutation ($userSettings: UserSettingsInput!) {
  updateUserSettings(userSettings: $userSettings) {
    ${USER_SETTINGS_FIELDS}
    deleted
  }
}`;

export const GET_ARTICLE = gql`
query ($articleSlug: String!) {
  articleBySlug(articleSlug: $articleSlug) {
    slug
		markdown
		title
		author
		createdAt
  }
}`;

export const GET_LEGAL_PAGE = gql`
query ($documentSlug: String!) {
  legalDocumentBySlug(documentSlug: $documentSlug) {
    slug
		markdown
		title
		author
		createdAt
  }
}`;

export const GET_ALL_QUESTIONS = gql`
{
  questionsWithAuth {
    uuid
    slug
    type
    text
    author
    createdAt
    createdBy
    modifiedAt
    tags {
      uuid
      slug
      title
    }
    nonTest
  }
}`;

export const GET_ALL_TAGS = gql`
{
  tags {
    uuid
    slug
    title
    description
    descriptionUrls
  }
}`;
