import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { withLoginProvider } from '../../containers/LoginProvider';
import { clientOnly } from '../../containers/ClientOnly';


class UserClaims extends Component {

	render() {

		if (this.props.userError) {
			console.log("UserClaims Error", this.props.userError);
			return false;
		}

		if (this.props.isLoadingUser) {
			console.log("UserClaims: user loading...");
			return false;
		}
		
		if (!this.props.user) {
			console.log("UserClaims: user is not logged in");
			return false;
		}
		
		console.log("UserClaims", this.props.user)
		return false;
	}
}

export default clientOnly(withLoginProvider(UserClaims));