import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../components/LoadingIndicator';
import Exception from '../components/Exception';
import { getLoginManager } from '../utils/loginManager';
import { getClientLogger }  from '../utils/clientLogger';
import { clientOnly }  from './ClientOnly';
import { withLoginProvider } from './LoginProvider';
import { isServer } from '../utils/ssrUtils';

// this is client-only component
const requireAuth = (ComposedComponent, ensureUserCallback) => {
	class RequireAuth extends Component {

		constructor(props) {
			super(props);
			this.loginManager = getLoginManager();
			getClientLogger();
		}

		componentWillMount() {
			Log.debug('requireAuth.componentWillMount');
			const { user, isLoadingUser, userError } = this.props;
			if (isServer() || isLoadingUser || userError) return;

			ensureUserCallback();
		}

		componentWillUpdate(nextProps) {
			Log.debug('requireAuth.componentWillUpdate');
			const { user, isLoadingUser, userError } = nextProps;
			if (isLoadingUser || userError) return;
			
			ensureUserCallback();
		}

		render() {
			const { user, isLoadingUser, userError } = this.props;

			if (userError) {
				if(this.loginManager.isAccessDeniedError(userError)) {
						return <Exception title='Error' desc='Access is denied, please try to log in or use different credentials' />;	
				}
			
				return <Exception desc={userError.message} />;
			}

			if (isLoadingUser && !user) return <LoadingIndicator />;

			return <ComposedComponent {...this.props} />;
		}

		componentDidMount() {
			Log.debug('requireAuth.componentDidMount');
		}

		componentDidUpdate() {
			Log.debug('requireAuth.componentDidUpdate');
		}
	}

	// FIXME !!! restore the checks
	// Authentication.defaultProps = {
	//   oidc: null,
	// };
	// Authentication.propTypes = {
	//   push: PropTypes.func.isRequired,
	//   isUserAuthenticated: PropTypes.bool.isRequired,
	//   isUserLoading: PropTypes.bool.isRequired
	// };

	return clientOnly(withLoginProvider(RequireAuth));
}

export { requireAuth };
