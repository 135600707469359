import React from 'react';
import { Button, Breadcrumb, Card, Progress, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { QuestionDisplay } from '@kindrobots/web.fe.ui';

import TaxonomyTags from '../TaxonomyTags';
import TaxonomyTagsCard from '../TaxonomyTagsCard';
import QuestionNav from '../QuestionNav';

import MetaInfo from '../MetaInfo';

import EmbeddedComments from '../EmbeddedComments';

import styles from './style.less';

const Answer = ({
  answer,
  taxonomy,
  toAnswer,
  toNextAnswer,
  toPrevAnswer,
  toReport,
  onExit
}) => {
  const {question} = answer;
  const first = !answer.prevQuestionSlug;
  const last = !answer.nextQuestionSlug;
  console.log('answer:', answer);

  const childprops = {
    question,
    answer: answer,
    disabled: true,
  };

  const percentage = Math.round(100*(answer.currentQuestionNumber)/answer.questionsTotalNumber);

  const answersMap = answer.answersMap && JSON.parse(answer.answersMap);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="home">
      <Link to="/challenges">Challenges</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key={answer.challengeSlug}>
      <a onClick={toReport}>{answer.challengeTitle.trim()}</a>
    </Breadcrumb.Item>
  ];

  return (
    <React.Fragment>
      <Card
        title={
          <Breadcrumb>
            {breadcrumbItems}
          </Breadcrumb>
        }
        bordered={false}
        className={styles.card}
      >
        <div className={styles.questionBox}>
          <Progress percent={percentage} strokeWidth={16} />
          {
            <QuestionNav
              answersMap={answersMap}
              currentSlug={question.slug}
              toAnswer={toAnswer}
            />
          }
          <QuestionDisplay {...childprops} />
        </div>
        <TaxonomyTags tags={question && question.tags ? question.tags : []} taxonomy={taxonomy} />
        <Button size='large' className={styles.controlButton} onClick={toReport} type="danger">Exit</Button>
        {
          !first
            ? <Button size='large' className={styles.controlButton} onClick={toPrevAnswer}>Previous</Button>
            : ''
        }
        {
          !last
            ? <Button size='large' className={styles.controlButton} onClick={toNextAnswer}>Next</Button>
            : <Button size='large' className={styles.controlButton} onClick={toReport}>Finish</Button>
        }
        <MetaInfo {...question} />
      </Card>
      <EmbeddedComments path={`/intro/${answer.challengeSlug}`} />
      <TaxonomyTagsCard tags={question && question.tags ? question.tags : []} taxonomy={taxonomy} />
    </React.Fragment>
  );
};

export default Answer;
