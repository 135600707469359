import React from 'react';

import Sources from '../Sources';

import { licensesMap } from '../../constants/licensesMap';

import styles from './style.less';

const LicenseDisplay = ({
	license,
	source,
  descriptionUrls
}) => {
  return (
    <React.Fragment>
      <div className={styles.header}></div>
      <div className={styles.license}>
      License: { licensesMap[license] }. {(source && descriptionUrls) && <Sources source={source} descriptionUrls={descriptionUrls} />}
      </div>
    </React.Fragment>
  );
};

export default LicenseDisplay;
