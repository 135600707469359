import React from 'react';

// TODO move these to global styles
const color = ['#f50', '#47d048', '#2db7f5'];
const defaultColor = '#108ee9';
const difficultyKey = 'difficulty-level';
const difficultyKeys = ['difficulty-easy', 'difficulty-medium', 'difficulty-hard'];
const cefrKey = 'cefr-level';

const selectWidths = {
  'cefr-level': 300,
  'difficulty-level': 120,
  'default': 420,
};

function chopTrees(flatTrees) {
  let cycle_guard = {};

  const sortByTitle = (a, b) => {
    const au = a.title.toUpperCase();
    const bu = b.title.toUpperCase();
    return au < bu ? -1 : (au === bu ? 0 : 1);
  };

  const sortByDifficulty = (a, b) => {
    const ai = difficultyKeys.indexOf(a.key);
    const bi = difficultyKeys.indexOf(b.key);
    return ai - bi;
  };


  const roots = flatTrees.filter(obj => obj.parentKey == null);
  roots.sort(sortByTitle);

  const chopDown = treenode => {
    const children = flatTrees.filter(obj => obj.parentKey == treenode.key);
    children.sort(treenode.key == difficultyKey ? sortByDifficulty : sortByTitle);
    treenode.children = children.map(obj => { return {...obj}; });
    treenode.children.forEach(child => {
      if (cycle_guard[child.id]) {
        //fail-safe
        child.children = [];
        return; //stop if we're going to cycle
      }
      cycle_guard[child.id] = true;
      chopDown(child);
    })
  };

  let trees = [];
  roots.forEach((root, index) => {
  	root.color = color[index%roots.length];
    root.selectWidth = selectWidths[root.key] ? selectWidths[root.key] : selectWidths.default;
    var tree = { ...root };
    chopDown(tree);
    trees.push(tree);
  })
  return trees;
}

export class Taxonomy {
  constructor(flatTrees) {
    this.flatTrees = flatTrees;
    this.trees = chopTrees(flatTrees);
  }

  findColor(tag) {
	  let node = this.flatTrees.filter(node => node.key == tag)[0];
	  if (!node)
	    return defaultColor;
	  while(!node.color && node.parentKey)
	    node = this.flatTrees.filter(n => n.key == node.parentKey)[0];
	  return node.color ? node.color : defaultColor;
  }

  getBreadcrumb(tagObj) {
    let node = this.flatTrees.filter(node => node.key == tagObj.slug)[0];
    if (!node) {
      return [{
        key: tagObj.slug,
        title: tagObj.title,
      }];
    }
    var path = [node];
    while(node.parentKey) {
      node = this.flatTrees.filter(n => n.key == node.parentKey)[0];
      path.unshift(node);
    }
    return path;
  }

  isChild(key) {
    let node = this.flatTrees.filter(node => node.key == key)[0];
    return node ? !!node.parentKey : false;
  }

  getSiblings(tagObj) {
    let node = this.flatTrees.filter(node => node.key == tagObj.slug)[0];
    if (!node) {
      return [];
    }
    return this.flatTrees.filter(n => n.parentKey == node.parentKey && n.key != node.key);
  }

  getChildren(tagObj) {
    return this.flatTrees.filter(node => node.parentKey == tagObj.slug);
  }

  isDifficulty(tag) {
	  return !!this.flatTrees.filter(node => node.key == tag.slug && node.parentKey == difficultyKey).length;
  }

  notDifficulty(tag) {
    return !this.isDifficulty(tag);
  }

  isCefr(tag) {
    return !!this.flatTrees.filter(node => node.key == tag.slug && node.parentKey == cefrKey).length;
  }

  notCefr(tag) {
    return !this.isCefr(tag);
  }

  splitDifficultyCefrOther(tags) {
    const difficulty = [];
    const cefr = [];
    const other = [];
    for(const t of tags)
    {
      if (this.isDifficulty(t))
	  	  difficulty.push(t);
      else if (this.isCefr(t))
        cefr.push(t);
      else
        other.push(t)
    }
	  return { difficulty, cefr, other };
  }

  // Fixed for multiple CEFR levels (which is legit)
  difficultyCefrThenRest(tags) {
    const split = this.splitDifficultyCefrOther(tags);
    return [...split.difficulty, ...split.cefr, ...split.other];
  }

  // Nick: Fixed to get rid of reversion
  cefrDifficultyLast(tags) {
    const split = this.splitDifficultyCefrOther(tags);
    return [...split.other, ...split.cefr, ...split.difficulty];
  }

  expandTags(tags) {
	  let expanded = [...tags];
	  tags.forEach(tag => {
	    const childTags = this.flatTrees.filter(entry => entry.parentKey == tag).map(entry => entry.key);
	    expanded = [...expanded, ...this.expandTags(childTags)]
	  });
	  return expanded;
  }

  findRootKeyByChildKey(childKey) {
	  let node = this.flatTrees.filter(node => node.key == childKey)[0];
	  if (!node)
	    return null;
	  while(node.parentKey)
	    node = this.flatTrees.filter(n => n.key == node.parentKey)[0];
	  return node.key;
  }

  belongsToTree(treeNode, childKey) {
  	  if (treeNode.key == childKey)
    	return true;
  	  return treeNode.children.some((child) => this.belongsToTree(child, childKey));
  }
}
