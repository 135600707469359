import React from 'react';
import { Card } from 'antd';

import EmbeddedReportComponent from '../EmbeddedReport';

import styles from './style.less';

const Report = ({
  report,
  onExit,
  toAnswers,
  toProfile
}) => {
  return (
    <Card className={styles.reportCard} bordered={false}>
      <EmbeddedReportComponent
        report={report}
        onExit={onExit}
        toAnswers={toAnswers}
        toProfile={toProfile}
      />
    </Card>
  );
};

export default Report;
