// TODO !!! setup Apollo to use uuid as Id

import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from './utils/config';

import { getLoginManager } from './utils/loginManager';

const httpLink = new HttpLink({ uri: new URL('graphql', config.apiUrl).href });
const authLink = setContext((request, previousContext) => {
  const { headers } = previousContext;
  const authenticated = getLoginManager().isUserAuthenticated();

  if (!authenticated) return { headers };

  // If the user is authenticated, then augment headers
  const token = getLoginManager().getUserAccessToken();
	// return the headers to the context so httpLink can read them
	
	const result = { headers: { ...headers } };
	if(token) result.headers.authorization = `Bearer ${token}`;
  return result;
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      if (!object.uuid && object.challengeUuid)
        return `${object.challengeUuid}${object.sessionUuid || ''}`;
      else
        return object.uuid;
    },
  }).restore(window.__APOLLO_STATE__),
  defaultOptions: {
    query: {
      // NOTE caching requires unique ids setup or __typename
      // see implementation of dataIdFromObject above
      fetchPolicy: 'cache-first',
    },
  },
  ssrForceFetchDelay: 1000, //If you are using fetchPolicy: network-only or fetchPolicy: cache-and-network on some of the initial queries, you can pass the ssrForceFetchDelay option to skip force fetching during initialization, so that even those queries run using the cache
});

export default apolloClient;
