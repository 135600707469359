import React from 'react';
import { Card } from 'antd';
import { isServer } from '../../utils/ssrUtils';
import config from '../../utils/config';

function scriptAlreadyIncluded(src){
    var scripts = document.getElementsByTagName("script");
    for(var i = 0; i < scripts.length; i++)
       if(scripts[i].getAttribute('src') == src) return scripts[i];
    return null;
}

const EmbeddedComments = ({path, title='Comments'}) => {
	if (!config.featureFlags || !config.featureFlags.forum)
		return null;

	if (isServer())
		return (
			<Card title={title}>
			</Card>
		);

	const discourseUrl = config.forumUrl.replace(/\/?$/, '/'); //add trailing slash
	const discourseEmbedUrl = (path ? (window.location.origin + path) : window.location.href.replace(window.location.search, '').replace(window.location.hash, '')).replace('https', 'http');

	const s = scriptAlreadyIncluded(discourseUrl + 'javascripts/embed.js');
	if (s) {
		s.parentNode.removeChild(s); //trigger IIFE again
		const d = document.getElementById('discourse-comments');
		if (d) {
			const i = d.getElementsByTagName('iframe');
			if (i) {
				for (let index = i.length - 1; index >= 0; index--) {
 				   	d.removeChild(i[index]);
				}
			}
		}
	}

	window.DiscourseEmbed = {
		discourseUrl,
		discourseEmbedUrl,
    discourseReferrerPolicy: 'no-referrer-when-downgrade'
	};
	const d = document.createElement('script');
	d.type = 'text/javascript';
	d.async = true;
	d.src = DiscourseEmbed.discourseUrl + 'javascripts/embed.js';
	(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(d);

	return (
		<Card title={title}>
				<div id='discourse-comments'></div>
		</Card>
	);
}

export default EmbeddedComments;
