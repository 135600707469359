export function isAnswerCorrect(answer) {
  return answer && answer.answerElements && answer.answerElements.every(ae => ae.correct);
}

export function getAnswersTagStats(answersArray) {
  if (!answersArray || !Array.isArray(answersArray)) throw new Error('getAnswersTagStats argument must be an array!');
  const stats = {};
  for(const a of answersArray) {
    const answerIsCorrect = isAnswerCorrect(a);

    for(const t of a.question.tags) {
      if(!stats[t.slug]) stats[t.slug] = { total: 0, correct: 0 };
      stats[t.slug].total++;
      if(answerIsCorrect) stats[t.slug].correct++;
    }

  }
  return stats;
}

export function getCEFRStats(tagStats) {
  const a1 = tagStats["cefr-a1"] || { correct: 0, total: 0 };
  const a2 = tagStats["cefr-a2"] || { correct: 0, total: 0 };
  const b1 = tagStats["cefr-b1"] || { correct: 0, total: 0 };
  const b2 = tagStats["cefr-b2"] || { correct: 0, total: 0 };
  const c1 = tagStats["cefr-c1"] || { correct: 0, total: 0 };
  const c2 = tagStats["cefr-c2"] || { correct: 0, total: 0 };

  return ({
    a1a2: { total: a1.total + a2.total, correct: a1.correct + a2.correct },
    b1b2: { total: b1.total + b2.total, correct: b1.correct + b2.correct },
    c1c2: { total: c1.total + c2.total, correct: c1.correct + c2.correct }
  });
};

export function getTagsTriage(tagStats, tagsToIgnore) {
  const triage = { strong: [], ok: [], weak: [] };
  for(const tagSlug of Object.keys(tagStats)) {
    if(tagsToIgnore && tagsToIgnore.some(tti => tti === tagSlug)) continue;

    const ratio = tagStats[tagSlug].correct / tagStats[tagSlug].total;
    if (ratio >= 0.85) triage.strong.push(tagSlug);
    else if (ratio >= 0.65) triage.ok.push(tagSlug);
    else triage.weak.push(tagSlug); 
  }
  return triage;
}

export function calculateCefrLevel(a1a2ratio, b1b2ratio, c1c2ratio) {
  const knowsSomething = 0.5;
  const knowsOK = 0.65;
  const knowsWell = 0.8;
  const total = a1a2ratio + b1b2ratio + c1c2ratio;

  let level = 'cefr-a1';

  const isA2 = (a1a2ratio >= knowsSomething) || (b1b2ratio >= knowsSomething) || (c1c2ratio >= knowsSomething);
  const isB1 = isA2 && ((total >= knowsSomething * 3) || (a1a2ratio >= knowsOK));
  const isB2 = isB1 && ((total >= knowsOK * 3) || ((a1a2ratio >= knowsOK) && (b1b2ratio >= knowsOK)));
  const isC1 = isB2 && (((a1a2ratio >= knowsOK) && (b1b2ratio >= knowsOK)) && ((a1a2ratio >= knowsWell) || (b1b2ratio >= knowsWell)));
  const isC2 = isC1 && (total >= knowsWell * 3);

  if (isA2) level = 'cefr-a2';
  if (isB1) level = 'cefr-b1';
  if (isB2) level = 'cefr-b2';
  if (isC1) level = 'cefr-c1';
  if (isC2) level = 'cefr-c2';

  return level;
}

export const selectByTags = (allQuestions, anyOfTags, allOfTags, number) => {
  // console.log("anyOfTags", anyOfTags);
  
  const tagQuestionsBank = {};
  for (const ttss of anyOfTags)  {
    tagQuestionsBank[ttss] = allQuestions.filter(q => q.tags.some(t => t.slug === ttss) && allOfTags.every(aot => q.tags.some(qt => qt.slug === aot) ));
  }
  // console.log("tagQuestionsBank", tagQuestionsBank);

  // join questions for all the tags into one array
  const questionsForAllDesiredTags = [];
  for(const tagSlug of Object.keys(tagQuestionsBank))
    questionsForAllDesiredTags.push(...tagQuestionsBank[tagSlug]);

  // console.log("questionsForAllDesiredTags", questionsForAllDesiredTags);
  // select number questions at random
  const questionsSelected = [];
  number = Math.min(number, questionsForAllDesiredTags.length);

  while(number > 0) {
    const randomIndex = Math.floor(Math.random() * questionsForAllDesiredTags.length);
    const selectedQuestion = questionsForAllDesiredTags.splice(randomIndex, 1)[0];
    questionsSelected.push(selectedQuestion);
    number--;
  }

  // console.log("questionsSelected", questionsSelected);
  return questionsSelected;
};

