/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Button, Breadcrumb, Card } from 'antd';
import { Link } from 'react-router-dom';

import TaxonomyTagsCard from '../TaxonomyTagsCard';
import EmbedDialog from '../EmbedDialog';

import EmbeddedQuestion from '../EmbeddedQuestion';

import * as TestType from '../../constants/testType';

import styles from './style.less';

class QuestionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmbed: false
    };
  }

  render() {
    const { question, onExit, site, path, testType } = this.props;

    const breadcrumbItems = [
      <Breadcrumb.Item key="home">
        <Link to="/">Home</Link>
      </Breadcrumb.Item>,
      <Breadcrumb.Item key="challenges">
        <Link to="/challenges" rel="nofollow">Challenges</Link>
      </Breadcrumb.Item>,
      <Breadcrumb.Item key={question.challengeSlug}>
        {question.challengeTitle.trim()}
      </Breadcrumb.Item>
    ];

    const isTest = testType && (testType === TestType.CEFR_LEVEL_TEST);

    const childprops = {
          question,
          mutating: this.props.mutating,
          taxonomy: this.props.taxonomy,
          showDate: !isTest,
          submitAnswer: this.props.submitAnswer,
          onExit: this.props.onExit,
          testType
        };

    const onCloseEmbedDialog = () => {
      this.setState({ showEmbed: false });
    }

    const onOpenEmbedDialog = () => {
      this.setState({ showEmbed: true });
    }

    let parts; let sharepath;
    /* Nick: quick fix for location not being available during SSR */
    if (path) {
      parts = path.split('/');
      parts.length = 3; //drop question slug
      sharepath = parts.join('/');
    }

    return (
      <React.Fragment>
        <Card
          title={
            <Breadcrumb>
              {breadcrumbItems}
            </Breadcrumb>
          }
          bordered={false}
          extra={/* Nick: quick fix for location not being available during SSR */path &&
                <><Button className={styles.shareButton} target="_blank" href={`https://www.facebook.com/sharer.php?u=${site}${sharepath}`} icon="facebook" shape="circle" size="small" />
                  <Button className={styles.shareButton} target="_blank" href={`https://twitter.com/share?url=${site}${sharepath}`} icon="twitter" shape="circle" size="small" />
                  {!isTest && (<Button shape="round" type="primary" size="small" onClick={onOpenEmbedDialog}>Embed</Button>)}
                </>}
        >
          <EmbeddedQuestion {...childprops} />
        </Card>
        {(!testType || (testType !== TestType.CEFR_LEVEL_TEST)) && (
          <TaxonomyTagsCard tags={question && question.tags ? question.tags : []} taxonomy={this.props.taxonomy} />)
        }
        {
          /* Nick: quick fix for location not being available during SSR */path &&
          <EmbedDialog site={site} path={path} isIntro={false} isVisible={this.state.showEmbed} close={onCloseEmbedDialog} />
        }
      </React.Fragment>
    );
  }
}

export default QuestionComponent;
