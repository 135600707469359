import React from 'react';
import { hydrate } from 'react-dom';

import { Router } from 'react-router-dom'; // react-router v4
import createBrowserHistory from 'history/createBrowserHistory';

import { ApolloProvider } from 'react-apollo';

import '../style.less';
import App from '../App';

import apolloClient from '../apolloClient';

const history = createBrowserHistory();

// import registerServiceWorker from './registerServiceWorker';

hydrate(
  <ApolloProvider client={apolloClient}>
    <Router history={history}>
    	<App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root'));
// registerServiceWorker();
